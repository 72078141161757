/*
.cube-grid{
    position: relative;
    width: calc( 100% - 213px );
    display: inline-block;
    top: 10px;
    transition: width 0.5s;
}
.close + .cube-grid {
    width: calc( 100% - 31px );
}
*/
.cube-grid .includeSubTotal{
    position: absolute;
    top: 0;
    left: 0;
}
.cube-grid .row-only-tree td{
    color: #519657;
}
.cube-grid .row-grand-total td{
    background-color: #eeeeee;
    font-weight: bold;
}

.ico-void{
    width: 35px;
}

.menu-item {
    font-size: 15px !important;
    padding: 0 !important;
}
.menu-item svg{
    vertical-align: top;
    color: #509556;
    font-size: 20px;
    width: 35px;
}

.menu-item i{
    font-size: 20px;
    vertical-align: top;
    width: 35px;
    color: #509556;
    text-align: center;
}

.cube-grid .custom-pager>div>span{
    display: none;
}

.cube-grid .drill-down-cell{
    cursor: pointer
}

.cube-grid .drill-down-cell:hover{
    text-decoration: underline;
}

.cube-grid .cube-top {
    position:           relative;
    display:            flex;
    justify-content:    flex-start;
    height:             30px;
    background-color:   #d7e5d8;
    border-bottom:      1px solid #509556;
    margin-top:         7px;
    border-top-left-radius:  6px;
    border-top-right-radius: 6px;
}

.cube-grid .cube-top .tabular-header {
    display:            flex;
    color:              #509556;
    vertical-align:     middle;
    line-height:        20px;
    padding-left:       3px;
    padding-top:        3px;
    overflow-x:         scroll;
    overflow-y:         hidden;
    width:              calc( 100vw - 358px);
}
.drawerClosed .cube-grid .cube-top .tabular-header {
    width:              calc( 100vw - 200px);
}
.cube-grid .cube-top.pivot-enabled .tabular-header {
    width:              calc( 100vw - 458px);
}
.drawerClosed .cube-grid .cube-top.pivot-enabled .tabular-header {
    width:              calc( 100vw - 306px);
}

.cube-grid .cube-top .tabular-header svg {
    font-size:          24px !important;
    line-height:        normal;
    height:             auto;
    margin-top:         -2px;
    cursor:             default;
}
.cube-grid .cube-top .tabular-header .step {
    cursor:             default;
    font-size:          14px;
    font-weight:        600;
    white-space:        pre;
}
.cube-grid .cube-top .tabular-header .step .link {
    cursor:             pointer;
    vertical-align:     top;
}
.cube-grid .cube-top .tabular-header .next {
    margin-left:        8px;
    margin-top:         -3px;
}
.cube-grid .cube-top .tabular-header .next svg {
    cursor:             pointer !important;
}

.cube-grid .cube-top .tabular-header .step .link:hover {
    text-decoration:    underline;
}

.cube-grid .cube-top .breadcrumbs {
    display: flex;
    color: #509556;
    vertical-align: middle;
    line-height: 20px;
    margin-left: 10px;
    padding-top: 2px;
    overflow-x: scroll;
    overflow-y: hidden;
    width: calc( 100vw - 338px );
}
.drawerClosed .cube-grid .cube-top .breadcrumbs {
    width: calc( 100vw - 171px );
}

.cube-grid .cube-top .breadcrumbs .home{
    color: #509556;
    position: relative;
    display: inline-flex;
    height: 22px;
    width: 22px;
}
.cube-grid .cube-top .breadcrumbs .home svg {
    height: 22px;
    width: 22px;
}
.cube-grid .cube-top .breadcrumbs .home svg:hover,
.cube-grid .cube-top .step        .home svg:hover {
    cursor: pointer;
    opacity: 0.8;
}

.cube-grid .cube-top .breadcrumbs .bread{
    cursor:         pointer;
    white-space:    pre;
}
.cube-grid .cube-top .breadcrumbs span,
.cube-grid .cube-top .breadcrumbs svg {
    display:        inline-block;
    vertical-align: top !important;
}
.cube-grid .cube-top .breadcrumbs .bread:hover{
    text-decoration: underline;
}
.cube-grid .cube-top .breadcrumbs .bread:last-of-type:hover {
    text-decoration: none;
    cursor: default;
}
.cube-grid .cube-top .breadcrumbs .bread:last-of-type {
    font-weight: bold;
}

.cube-grid .cube-top .breadcrumbs svg{
    vertical-align: middle;
}

.cube-grid .cube-top .grid-menu{
    position: absolute;
    top: 0px;
    right: 15px;
    font-size: 14px;
    z-index: 1000;
    cursor: pointer;
    margin-top: 3px;
    height: 24px;
    line-height: 25px;
    color: #509556;
    border-left: 1px solid #509556;
    padding-left: 20px;
}
.cube-grid .cube-top .grid-menu button{
    padding: 0;
    margin-top: 0;
    color: #509556;
}

.cube-grid .cube-top .pivot-menu{
    position: absolute;
    top: 0px;
    right: 120px;
    font-size: 14px;
    z-index: 1000;
    cursor: pointer;
    margin-top: 3px;
    height: 24px;
    line-height: 25px;
    color: #509556;
    border-left: 1px solid #509556;
    padding-left: 20px;
}
.cube-grid .cube-top .pivot-menu button{
    padding: 0;
    margin-top: 0;
    color: #509556;
}

.pivot-item{
    display: flex;
    padding: 0 10px;
}

.pivot-item .pivot-item-check{
    min-width: 148px;
}

.pivot-item .pivot-item-filter{
    
    color: #a1a1a1;
    background-color: white;
    border: 1px solid #a1a1a1;
    margin-top: 7px;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    transition: transform .1s;
    cursor: default;
    z-index: 100;
}
.pivot-item .pivot-item-filter svg{
    font-size: 25px;
}
.pivot-item .pivot-item-filter.enabled{
    color: #616161;
    border: 1px solid #c8a415;
    cursor: pointer;
}
.pivot-item .pivot-item-filter.enabled:hover{
    transform: scale(1.1);
}
.pivot-item .pivot-item-filter.active{
    color: white;
    background-color: #519657;
    border: 1px solid #a1a1a1;
    cursor: pointer;    
}
.pivot-item .pivot-item-filter.active:hover{
    transform: scale(1.1);
}

.cube-grid .cube-bottom{
    display: flex;
    justify-content: flex-start;
    height: 40px;
    padding-left: 21px;
    background-color: #d7e5d8;
    border-top: 1px solid #509556;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    -webkit-box-shadow: 0px 5px 9px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 5px 9px -6px rgba(0,0,0,0.75);
    box-shadow: 0px 5px 9px -6px rgba(0,0,0,0.75);
    overflow: hidden;
}

.cube-grid .cube-bottom .total-count{
    display: flex;
    font-size: 14px;
    z-index: 1000;
    color: #313131;
    min-width: 146px;
    align-items: center;
    justify-content: flex-start;
}
.cube-grid .cube-bottom .total-count span:nth-of-type(2) {
    margin: 0 5px;
    font-weight: bold;
}

.cube-grid .cube-bottom .show-hide-columns{
    margin-left: 18px;
    font-size: 14px;
    z-index: 1000;
    line-height: 40px;
    color: #313131;
}

.cube-grid .cube-bottom .submenu-bottombar {
    min-width: 110px;
}

.cube-grid .cube-bottom .tabular-mode-button,
.cube-grid .cube-bottom .drill-mode-button,
.cube-grid .cube-bottom .invert-pivot-button,
.cube-grid .cube-bottom .rowtotal-button,
.cube-grid .cube-bottom .colslocked-button,
.cube-grid .cube-bottom .submenu-bottombar {
    display: flex;
    margin-left: 25px;
    z-index: 1000;
    color: #313131;
}
.cube-grid .cube-bottom .tabular-mode-button > span,
.cube-grid .cube-bottom .drill-mode-button   > span,
.cube-grid .cube-bottom .invert-pivot-button > span,
.cube-grid .cube-bottom .rowtotal-button     > span,
.cube-grid .cube-bottom .colslocked-button   > span,
.cube-grid .cube-bottom .submenu-bottombar   > span {
    display: flex;
    line-height: normal;
    align-items: center;
}
.cube-grid .cube-bottom .tabular-mode-button > div,
.cube-grid .cube-bottom .drill-mode-button   > div,
.cube-grid .cube-bottom .invert-pivot-button > div,
.cube-grid .cube-bottom .rowtotal-button     > div,
.cube-grid .cube-bottom .colslocked-button   > div,
.cube-grid .cube-bottom .submenu-bottombar   > div {
    margin-left: 15px;
}
.cube-grid .cube-bottom .tabular-mode-button button,
.cube-grid .cube-bottom .drill-mode-button   button,
.cube-grid .cube-bottom .invert-pivot-button button,
.cube-grid .cube-bottom .rowtotal-button     button,
.cube-grid .cube-bottom .colslocked-button   button,
.cube-grid .cube-bottom .submenu-bottombar   button {
    margin-top: 5px;
    padding: 3px 10px;
    font-size: 0.75rem !important;
}
.cube-grid              .rowtotal-button     button span {
    font-size: 0.74rem !important;
    font-weight: bold;
}

/* .cube-grid .cube-bottom .tabular-mode-button button span, */
.cube-grid .cube-bottom .subtotals-mode     button span {
    overflow: hidden;
    height: 22px;
    min-width: 112px;
    width: 112px;
    word-wrap: break-word;
}

.cube-grid .cube-bottom .measure-columns{
    margin-left: 15px;
    font-size: 14px;
    z-index: 1000;
    line-height: 40px;
    color: #313131;
}
.cube-grid .cube-bottom .pager{
    position: absolute;
    z-index: 1001;
    right: 24px;
    background-color: #d7e5d8;
    color: #509556;
    padding-top: 5px;
    overflow: hidden;
}
/*
.cube-grid table thead th[colspan="1"]{
    color: #000000 !important;
}
*/
.cube-grid .paper-column-ctrl{
    bottom:         0;
    margin-left:    -2px !important;
    padding:        13px 29px;
    border-top:     1px solid #509556;
    border-radius:  0;
}
.cube-grid .paper-column-ctrl.value-types {
    padding:        8px 30px;
    width:          237px;
    display:        inline-flex;
    flex-direction: column;
    align-items:    center;
}
.cube-grid .paper-column-ctrl.value-types.blocking:after {
    content:    '';
    position:   absolute;
    top:        0;
    left:       0;
    width:      100%;
    height:     100%;
    background: rgba(255, 255, 255, .5);
    z-index:    99999;
}

.cube-grid .paper-column-ctrl.value-types button:disabled {
    opacity:        0.25;
}
.cube-grid .delta-measure.hidden {
    display:        none;
}

.cube-grid .paper-column-ctrl button {
    background-color: #d7e5d8;
    border:           1px solid #509556 !important;
    margin-left:      4px !important;
    border-radius:    4px;
}

.cube-grid .paper-column-ctrl > p {
    display:        block;
    font-size:      12px;
    font-weight:    bold;
    margin-bottom:  5px;
    text-align:     center;
}
.cube-grid .paper-column-ctrl > p > span {
    display:        inline-block;
}

.drawerClosed .cube-grid .paper-column-ctrl:not(.value-types) {
    margin-left:    0;
    margin-top:     0;
    height:         130px;
    left:           10px;
    bottom:         5px;
}

.drawerClosed .cube-grid .paper-column-ctrl.value-types {
    width:          70px;
    padding:        8px;
}

.drawerClosed .cube-grid .paper-column-ctrl.value-types > p > span {
    width:          55px;
    white-space:    pre;
    overflow:       hidden;
    text-overflow:  ellipsis;
}

.drawerClosed .cube-grid .paper-column-ctrl > div {
    flex-direction: column;
    align-items:    flex-end;
}

.cube-grid .paper-column-ctrl .btn-label{
    height:         24px;
    font-size:      17px;
    font-weight:    400;
    color:          #000000;
    width:          40px;
    border:         0;
}

.cube-grid .paper-column-ctrl .MuiToggleButton-root.Mui-selected{
    background-color: #519657 !important;
    color:          white !important;
}

.fa-stack {
    height:         auto;
    vertical-align: top;
}
.fa-stack-1x, .fa-stack-2x {
    top:            -10px;
}
.fa-stack-1x {
    line-height:    20px;
    zoom:           0.75;
}
.fa-stack-2x {
    z-index:        101;
    zoom:           0.75;
    left:           12px;
    top:            -7px;
}

.resetIcon, .excelIcon {
    margin-left:    25px;
}

.excelIcon.disabled,
.excelIcon.disabled .fas.fa-file-excel {
    color:          grey !important;
}

.dimGrid {
    position:       relative;
    overflow:       scroll;
    height:         calc( 100vh - 155px);
    border-radius:  0 !important;
}

.zoom3 .dimGrid { height: calc(   77vh - 155px); }
.zoom2 .dimGrid { height: calc(   84vh - 155px); }
.zoom1 .dimGrid { height: calc(   91vh - 155px); }
.zoom-1 .dimGrid { height: calc( 111vh - 155px); }
.zoom-2 .dimGrid { height: calc( 125vh - 155px); }
.zoom-3 .dimGrid { height: calc( 143vh - 155px); }

.measureColumn {
    color:          green;
}

.formgroup {
    padding-left:   25px;
    padding-bottom: 20px;
    width:          260px;
}

.nextArrow {
    color:          green;
}

.show-all-values.hidden,
.subtotals-mode.hidden   {
    display:        none !important;
}

.show-all-values span {
    white-space: pre;
}

.cube-grid .cube-bottom .internalLabel {
    padding-right: 10px;
}

@media (max-width: 1200px) {
    .cube-grid .cube-bottom .internalLabel {
        width: 0;
        padding-right: 0;
        color: transparent;
    }
    .cube-grid .cube-bottom div {
        margin-left: 0 !important;
    }
    .cube-grid .cube-bottom .submenu-bottombar {
        min-width: 55px;
    }
    .logged-user-name {
        display: none;
    }
}

.tabular-mode-button label,
.drill-mode-button   label,
.invert-pivot-button label,
.colslocked-button   label,
.rowtotal-button     label {
    margin-top:     -3px;
    margin-left:    10px;
    margin-right:   0;
}


.tabular-mode-button label button,
.drill-mode-button   label button,
.invert-pivot-button label button,
.colslocked-button   label button,
.rowtotal-button     label button,
.autoreload-button   label button {
    border:         1px solid #519657;
    padding:        1px;
    min-width:      0;
    margin-top:     4px;
}
.autoreload-button   label button {
    margin-top:     1px;
    margin-left:    4px;
}

.rowtotal-button     label button {
    min-width:      26px;
}

.tabular-header span.home {
    vertical-align: top;
    margin-right: 5px;
    position: relative;
    display: inline-flex;
    height: 22px;
    width: 22px;
}
.tabular-header span.home svg {
    height: 22px;
    width: 22px;
}

.tableNoData {
    margin:     auto;
    position:   relative;
    top:        50%;
}

.tableNoData td {
    color:          silver;
    border:         1px solid silver;
    border-radius:  4px;
    padding:        10px;
    font-size:      18px;
    text-align:     center;
    cursor:         default;
}
.tableNoData u {
    cursor:         pointer;
    color:          grey;
}
.tableNoData u:hover {
    cursor:         pointer;
    color:          black;
}

.rotate90toLeft {
    transform:      rotate(90deg);
}

.rotate180 {
    transform:      rotate(180deg);
}

.flipX {
    transform:      scaleX(-1);;
}

.autoreload-button label {
    margin-left:    4px;
    margin-right:   4px;
}

.subtotals-mode.enabled i::before {
    display:                inline-block;
    color:                  #d7e5d8;
    z-index:                1;
    position:               absolute;
    top:                    9px;
    left:                   6px;
}
.subtotals-mode.enabled i::after {
    content:                '';
    display:                inline-block;
    width:                  26px;
    height:                 24px;
    background-color:       #519657;
    z-index:                -1;
    position:               absolute;
    top:                    7px;
    left:                   3px;
    border-radius:          3px;
}

::-webkit-scrollbar{
    width:                  0.8em;
    height:                 0.8em;
 /* background:             transparent; */
    margin-right:           10px;
}
::-webkit-scrollbar:hover{
 /* background-color:       #f2f2f2; */
    border:                 none;
}
::-webkit-resizer{
    -webkit-border-radius:  4px;
 /* background-color:       #d9d9d9; */
    border:                 none;
}
::-webkit-scrollbar-thumb{
    min-height:             0.8em;
    min-width:              0.8em;
    -webkit-border-radius:  4px;
    background-color:       rgba(191,191,191,0.65);
    border:                 none;
}
::-webkit-scrollbar-thumb:hover{
    background-color:       rgba(191,191,191,1);
    border:                 none;
}
::-webkit-scrollbar-thumb:active{
    background-color:       rgba(191,191,191,1);
    border:                 none;
}

.tabular-header::-webkit-scrollbar{
    width:                  0.6em;
    height:                 0.6em;
 /* background:             #f2f2f2; */
    margin-right:           10px;
}
.tabular-header::-webkit-scrollbar:hover{
 /* background-color:       #f2f2f2; */
    border:                 none;
}
.tabular-header::-webkit-resizer{
    -webkit-border-radius:  4px;
 /* background-color:       #d9d9d9; */
    border:                 none;
}
.tabular-header::-webkit-scrollbar-thumb{
    min-height:             0.6em;
    min-width:              0.6em;
    -webkit-border-radius:  4px;
    background-color:       rgba(191,191,191,0.65);
    border:                 none;
}
.tabular-header::-webkit-scrollbar-thumb:hover{
    background-color:       rgba(191,191,191,1);
    border:                 none;
}
.tabular-header::-webkit-scrollbar-thumb:active{
    background-color:       rgba(191,191,191,1);
    border:                 none;
}