/* ------- stili generali ------- */

.SimpleTable-wrapper {
    width:              100%;
    overflow-y:         scroll;
}

.SimpleTable-wrapper.for-users,
.SimpleTable-wrapper.for-filters {
    max-height:         calc(100vh - 376px);
}

.SimpleTable table {
    overflow:           scroll;
    border-spacing:     0;
    table-layout:       fixed;
}

.SimpleTable thead > tr:nth-child(2) > th:nth-child(1) {
    padding-left:       8px;
}

.SimpleTable thead tr th,
.SimpleTable tr.highlight td {
    position:           -webkit-sticky;
    position:           sticky;
    z-index:            100;
    background-color:   white;
    top:                0;
}
.SimpleTable thead tr:nth-of-type(2) th {
    top:                41px; /* 37px; */
}
.SimpleTable thead tr:nth-of-type(3) th {
    top:                calc( 41px * 2 ); /* 37px; */
}
.SimpleTable tr.highlight td {
    top:                calc( 41px * 3 ); /* 37px; */
}

.SimpleTable th,
.SimpleTable td {
    padding:            8px;
    border-bottom:      1px solid rgb(224, 224, 224);
    font-size:          0.875rem;
}

.SimpleTable th:not(.check):not(.edit),
.SimpleTable td:not(.check):not(.edit)
{
    white-space:        pre;
    overflow:           hidden;
    text-overflow:      ellipsis;
}

.SimpleTable th {
    text-align:         left;
    color:              rgb(94, 94, 94);
    font-weight:        500;
    font-family:        "Roboto", "Helvetica", "Arial", sans-serif;
}

.SimpleTable td:first-of-type {
    color:              rgb(74, 74, 74);
    font-weight:        500;
}

.SimpleTable tbody tr:nth-of-type(odd)  {
    background-color:   rgb(249, 249, 249);
}
.SimpleTable tbody tr:nth-of-type(even)  {
    background-color:   rgb(253, 253, 253);
}

/* ------- stili checkbox ------- */

.SimpleTable td.check,
.SimpleTable td.edit   {
    padding-left:       20px;
    padding-right:      15px;
    padding-top:        8px;
    padding-bottom:     8px;
}

.SimpleTable .check > span {
    padding:            0;
}
/* rimosso temporaneamente
.SimpleTable .check > div {
    min-width:          79px;
}*/


tr.filter input {
    font-size:          14px;
}

tr.filter th {
    white-space:        normal !important;
}

.SimpleTable .edit     { min-width: 47px; width: 47px; max-width: 47px; }
.SimpleTable .check    { min-width: 47px; width: 47px; max-width: 47px; }
.SimpleTable .label    { min-width: 170px; width: 170px; }
.SimpleTable .value    { min-width: 200px; width: 200px; }
.SimpleTable .selected { width:  50px; }
.SimpleTable .sort     { width: 100px; }
.SimpleTable .drill    { width: 200px; }
.SimpleTable .exclude  { width:  75px; }
.SimpleTable .filters  { width: auto;  }

.SimpleTable .check-sel-all {
    position: relative;
    left:   22px;
}

.SimpleTable tr.highlight td {
    background-color: /* rgb(234, 244, 234) */ rgb(189, 219, 191) !important;
}
