.impersonate-user{
    background-color: white;
    margin-top: 20px;
    padding: 20px 20px 20px 20px;
}
.impersonate-user .row{
    max-width: 400px;
}
.impersonate-user .submit-buttons{
    margin-top: 30px;
    display: flex;
}
.impersonate-user .submit-buttons .button-space{
    flex-grow: 1;
}
.impersonate-user .submit-buttons .button{
}