.users-editing {
    margin-top: 20px;
}

.users-editing td, .users-editing th {
    font-size: 14px;
    padding: 10px;
    vertical-align: middle;
}

.bold {
    font-weight: bold;
}


.users-editing .grid-parent{
    display: flex;
}

.users-editing .grid-parent .grid-child{
    padding-right: 30px;
    min-height: 800px;
}

.users-editing .grid-parent .grid-child.left{
    border-right: 1px solid #519657;
}

.users-editing .grid-parent .grid-child.right{
    width: 100%;
    padding-top: 10px;
    background-color: #fbfbfb;
    padding-left: 25px;
}

.users-editing .priv-cell{
    border-right: 1px solid #dddddd;
    width: 250px;
}

.users-editing .check-cell{
    width: 50px;
    background-color: #b8ddb8;
    color: green;
    cursor: pointer;
}

.users-editing .check-cell:hover {
    background-color: #a6c6a6;
}
.users-editing .block-cell{
    width: 50px;
    background-color: #e7bdbd;
    color: #be0000;
    cursor: pointer;
}

.users-editing .block-cell:hover {
    background-color: #caa5a5;
}

.users-editing .icon-link-cell{
    cursor: pointer;
}
.users-editing .icon-link-cell svg{
    color: #519657;
}

.users-editing .icon-link-cell:hover svg{
    filter: brightness(75%);
}

.users-editing .users-list {
    cursor: default;
    margin-top: 10px;
}

.users-editing .users-list thead tr:first-of-type {
    background-color: rgb(184, 221, 184, 0.2);
}

.users-editing .users-list table {
    
}

.users-editing .row:hover {
    cursor: default;
    background-color: rgba(0,0,0,0.05);
}

.users-editing svg {
    cursor: pointer;
    vertical-align: middle;
}
.users-editing .STATUS svg {
    cursor: default;
}
/*
.users-editing .row.selected {
    background-color: rgb(200, 230, 200);
}

.users-editing .check-selected{
    background-color: rgb(200, 230, 200);
}
*/
.users-editing td svg{
    vertical-align: middle;
}

.users-editing .cell-user.disabled{
    background-color: #fbe9e0;
    color: #ce4040;
}   

.users-editing .check-selected svg{
    color: #519657;
}

.users-editing .check-not-selected{
    background-color: #f7f7f7;
}

.users-editing .check-not-selected svg{
    color: #ffffff;
}

.users-editing .check-not-selected:hover{
    background-color: #f7f7f7;
}

.users-editing .check-not-selected:hover svg{
    color: #519657;
}

.users-editing .selected-group{
    color: #519657;
    font-weight: bold;
}

.users-editing .select-all {
    display: block;
}

.users-editing .select-all span {
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer;
    color: #519657;
    margin-right: 20px;
}

.users-editing .container {
    display: inline-flex;
    width: 100%;
    flex-direction: column;
}

.users-editing .container > div {

    margin-right: 20px;
}

.users-editing .container > div:last-of-type {
    margin: 0;
}

.users-editing .USERS {
    width: auto
}

.users-editing .CUB_DIM_MEA > div {
    margin: 1px;
}

.users-editing .SimpleTable-wrapper {
    height:             100%;
    max-height:         calc(100vh - 100px);
}

.users-editing .SimpleTable-wrapper.CUBES,
.users-editing .SimpleTable-wrapper.MEASURES,
.users-editing .SimpleTable-wrapper.DIMENSIONS {
    max-height:         calc(100vh - 270px);
}

.users-editing .SimpleTable td,
.users-editing .SimpleTable th {
    cursor: default;
}

/*
.users-editing .SimpleTable tr.highlight {
    background-color: rgb(81,150,87) !important; *//* rgb(234, 244, 234) !important; *//*
}
*/
.users-editing .SimpleTable.cubes tr th,
.users-editing .SimpleTable.cubes tr td  {
    position:   relative !important;
    top:        unset !important;
}

.users-editing .SimpleTable td.edit {
    color: rgb(166,166,166);
}

.users-editing .SimpleTable .highlight td.edit {
    color: rgb(255,255,255);
}
.users-editing .SimpleTable td.edit:hover {
    color: rgb(81,150,87);
}

/*
.cubes-settings .tab-panel-dimensions-measures {
    display: flex;
    flex-direction: row;
}*/

.users-editing .SimpleTable.usergroups .check-sel-all,
.users-editing .SimpleTable.users      .check-sel-all { left: 12px; }

.users-editing .SimpleTable.dimensions .check-sel-all,
.users-editing .SimpleTable.measures   .check-sel-all { left: 10px; }


.users-editing .SimpleTable.cubes td {
    font-weight: 400;
}

.users-editing .accordionSummary > div > div {
    color: #5E5E5E;
    font-weight: 500;
}

.users-editing .accordionSummary  > div.Mui-expanded svg {
    display: none;
}

.users-editing th.USER_GROUP_LIST ::placeholder,
.users-editing th.USER_GROUP_LIST,
.users-editing td.USER_GROUP_LIST {
    text-align: center;
}

.users-editing thead tr th {
    border-top-color:       white;
    border-bottom-color:    white;
}

.users-editing thead tr:last-of-type th {
    border-top:             1px solid lightgrey;
    border-bottom:          1px solid lightgrey;
}
/*
.users-editing :not(th.colgroup) + th.colgroup {
    border-right:           1px solid lightgrey !important;
}
*/
.users-editing th.Users + th.User.Groups {
    border-left:            1px solid lightgrey !important;
}
/*
.users-editing th.ingroup:last-of-type {
    border-right: 1px solid lightgrey !important;
}
*/
.users-editing thead tr:first-of-type th {
    color: rgb(180,180,180);
}

.editFields > div {
    display: block !important;
    margin-bottom: 15px;
}

.editFields > div > div {
    width: 100%;
}

.editFields .FLAG_ADMIN > label {
    font-size: 75%;
}

.editFields .AGENTE_COD  {
    min-width: 350px;
}

.users.editing-dialog .Mui-disabled {
    color: grey !important;
}

.users.editing-dialog input.Mui-disabled{
    color: rgb(70,70,70) !important;
}

.users.editing-dialog svg.Mui-disabled {
    color: transparent !important;
}

.users.editing-dialog  .Mui-disabled:before {
    border-bottom: none !important;
}

li[data-value=all] {
    min-height: 30px;
}

.users-editing .✕ {
    font-size: 100%;
    color: rgb(220,220,220);
}
.users-editing .✓ {
    color: green;
    /*font-size:120%;*/
    /*color: rgb(80,80,80);*/
}

.users-editing .✓,
.users-editing .✕ {
    font-weight: bold;
}

.users-editing .✓:hover,
.users-editing .✕:hover {
    cursor: pointer;
}

.users-editing .USER_GROUP_LIST span,
.users-editing .USER_GROUP_LIST input,
.users-editing .USER_GROUP_LIST svg   {
    padding: 0 !important;
    margin:  0 !important;
}

.users-editing :not(td.STATUS) + td.STATUS {
    padding-left:  0;
    padding-right: 0;
}

.users-editing .row-disabled td,
.users.editing-dialog .deleteBtn {
    color: #ce4040;
}

.users-editing td.FLAG_ROOBEEK {
    padding:            0;
}

.users-editing td.DATA_ULTIMA_LOGIN {
    text-align:         right;
}

.users-editing .roobeekIcon {
    min-width:          38px;
        width:          38px;
    max-width:          38px;
    padding:            0;
}

.editFields > div > div > input {
    white-space:        pre;
    overflow:           hidden;
    text-overflow:      ellipsis;
}

.users.editing-dialog .FLAG_ATTIVO .select {
    width:              90px;
    margin-right:       15px;
}

.users.editing-dialog .FLAG_ATTIVO .Disabled {
    width:              70%;
}

.users.editing-dialog .container {
    display:            flex;
    align-items:        center;
}

.users.editing-dialog .checkbox {
    margin-left:        -12px;
}

.users.editing-dialog .inputLabel {
    color:              rgb(70,70,70) !important;
}


.users-editing .container.red {
    border: 1px solid red;
}
.users-editing .container.blue {
    border: 1px solid blue;
}

.users-editing .ins-new-user-btn {
    position:       absolute;
    top:            92px;
    margin-left:    10px !important;
    z-index:        1001;
}

.users-editing .colgroup {
    padding-top: 20px;
    padding-bottom: 0;
}