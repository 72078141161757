.no-match {
    background: #519657;
    margin: 0;
    color: #fff;
    height: 100%;
}

.no-match span {
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;

    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;

    opacity: 1;
}

.no-match a:hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; /* IE 8 */
    filter: alpha(opacity=50); /* IE7 */
    opacity: 0.6;
}

.no-match a, .no-match a:visited, .no-match a:active {
    color: #000;
    text-decoration: none;
}

.no-match .container {
    position: relative;
    max-width: 400px;
    _width: 400px;
    margin: 0 auto 80px;
    text-align: center;
    height: 100%;
}

.no-match .container .back{
    margin: 40px 10px 0;
}

.no-match h1 {
    margin: 0;
}

.no-match h2 {
    font-size: 16px;
    font-size: 1.3rem;
    font-weight: 400;
    margin: 50px 10px 20px 0px;
    padding: 4px 0 10px;
    color: #c5c5c5;
}

.no-match h3 {
    margin: 20px 0 8px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    padding: 0 30px;
}

.no-match .warning {
    margin: 45px 0 10px 0;
    padding: 0px 10px 8px;
}

.no-match .warning p {
    font-size: 12px;
    font-size: 1rem;
    text-align: justify;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.no-match .back span {
    display: block;
    padding: 20px 10px;
    background: #ffd54f;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    color: black;
    cursor: pointer;
    border-radius: 3px;
}

.no-match .copyright {
    font-size: 12px;
    text-align: center;
}

.no-match .logo {
    max-width: 100%;
    text-align: center;
    padding-top:100px;
    height: 150px;
}

.no-match .logo a:hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; /* IE 8 */
    filter: alpha(opacity=100); /* IE7 */
    opacity: 1;
}

.no-match .four-oh-four {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    margin-top: 10px;
}

.no-match .four-oh-four svg{
    font-size: 39px;
    vertical-align: top;
}



/* Responsive
-------------------------------------------------------*/

/* Desktop only */
@media only screen and (min-width : 1800px) {
    .no-match h2 {
        font-size: 28px;
        font-size: 1.75rem;
    }
    .no-match .warning p {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
        font-size: 14px;
        font-size: 1rem;
    }
}

@media only screen and (max-width : 568px) {
    .no-match  .warning span {
        color: #000;
        background: #ffd54f;
    }
    .no-match  h2 {
        color: #c5c5c5;
    }
}

@media only screen and (max-width : 320px) {
    .no-match  .four-oh-four {
        height:40px;
        margin: 10px auto 10px;
    }
    .no-match  h2 {
        font-size: 0.88rem;
        font-weight: bold;
    }
    .no-match  .warning {
        margin: 0;
    }
    .no-match  .warning p {
        margin-top: 10px;
        font-size: 0.93rem;
    }
    .no-match .warning span {
        margin-top: 20px;
        font-size: 0.93rem;
    }

}