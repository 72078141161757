.users-settings {
    margin-top: 20px;
}

.users-settings td, .users-settings th {
    font-size: 14px;
    padding: 10px;
    vertical-align: middle;
}

.bold {
    font-weight: bold;
}


.users-settings .grid-parent{
    display: flex;
}

.users-settings .grid-parent .grid-child{
    padding-right: 30px;
    min-height: 800px;
}

.users-settings .grid-parent .grid-child.left{
    border-right: 1px solid #519657;
}

.users-settings .grid-parent .grid-child.right{
    width: 100%;
    padding-top: 10px;
    background-color: #fbfbfb;
    padding-left: 25px;
}

.users-settings .priv-cell{
    border-right: 1px solid #dddddd;
    width: 250px;
}

.users-settings .check-cell{
    width: 50px;
    background-color: #b8ddb8;
    color: green;
    cursor: pointer;
}

.users-settings .check-cell:hover {
    background-color: #a6c6a6;
}
.users-settings .block-cell{
    width: 50px;
    background-color: #e7bdbd;
    color: #be0000;
    cursor: pointer;
}

.users-settings .block-cell:hover {
    background-color: #caa5a5;
}

.users-settings .icon-link-cell{
    cursor: pointer;
}
.users-settings .icon-link-cell svg{
    color: #519657;
}

.users-settings .icon-link-cell:hover svg{
    filter: brightness(75%);
}

.users-settings .users-list {
    cursor: default;
    margin-top: 10px;
}

.users-settings .users-list thead tr:first-of-type {
    background-color: rgb(184, 221, 184, 0.2);
}

.users-settings .users-list table {
    
}

.users-settings .row:hover {
    cursor: default;
    background-color: rgba(0,0,0,0.05);
}

.users-settings svg {
    cursor: pointer;
    vertical-align: middle;
}
/*
.users-settings .row.selected {
    background-color: rgb(200, 230, 200);
}

.users-settings .check-selected{
    background-color: rgb(200, 230, 200);
}
*/
.users-settings td svg{
    vertical-align: middle;
}

.users-settings .cell-user.disabled{
    background-color: #fbe9e0;
    color: #ce4040;
}   

.users-settings .check-selected svg{
    color: #519657;
}

.users-settings .check-not-selected{
    background-color: #f7f7f7;
}

.users-settings .check-not-selected svg{
    color: #ffffff;
}

.users-settings .check-not-selected:hover{
    background-color: #f7f7f7;
}

.users-settings .check-not-selected:hover svg{
    color: #519657;
}

.users-settings .selected-group{
    color: #519657;
    font-weight: bold;
}

.users-settings .select-all {
    display: block;
}

.users-settings .select-all span {
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer;
    color: #519657;
    margin-right: 20px;
}

.users-settings .container {
    display: inline-flex;
    width: 100%;
}

.users-settings .container > div {

    margin-right: 20px;
}

.users-settings .container > div:last-of-type {
    margin: 0;
}

.users-settings .USERS {
    width: 50%;
}
.users-settings .USERS_GROUPS {
    width: 25%;
}
.users-settings .CUB_DIM_MEA {
    width: 25%;
}

.users-settings .CUB_DIM_MEA > div {
    margin: 1px;
}

.users-settings .SimpleTable-wrapper {
    height:             100%;
    max-height:         calc(100vh - 100px);
}

.users-settings .SimpleTable-wrapper.CUBES,
.users-settings .SimpleTable-wrapper.MEASURES,
.users-settings .SimpleTable-wrapper.DIMENSIONS {
    max-height:         calc(100vh - 270px);
}

.users-settings .SimpleTable td,
.users-settings .SimpleTable th {
    cursor: default;
}

/*
.users-settings .SimpleTable tr.highlight {
    background-color: rgb(81,150,87) !important; *//* rgb(234, 244, 234) !important; *//*
}
*/
.users-settings .SimpleTable.cubes tr th,
.users-settings .SimpleTable.cubes tr td  {
    position:   relative !important;
    top:        unset !important;
}

.users-settings .SimpleTable td.edit {
    color: rgb(166,166,166);
}

.users-settings .SimpleTable .highlight td.edit {
    color: rgb(255,255,255);
}
.users-settings .SimpleTable td.edit:hover {
    color: rgb(81,150,87);
}

/*
.cubes-settings .tab-panel-dimensions-measures {
    display: flex;
    flex-direction: row;
}*/

.users-settings .SimpleTable.usergroups .check-sel-all,
.users-settings .SimpleTable.users      .check-sel-all { left: 12px; }

.users-settings .SimpleTable.dimensions .check-sel-all,
.users-settings .SimpleTable.measures   .check-sel-all { left: 10px; }


.users-settings .SimpleTable.cubes td {
    font-weight: 400;
}

.users-settings .accordionSummary > div > div {
    color: #5E5E5E;
    font-weight: 500;
}

.users-settings .accordionSummary  > div.Mui-expanded svg {
    display: none;
}
