:root {
    --altezzaRiga:      29px;
    --verde06:          hsl(130, 20%, 62%);
    --verde05:          hsl(130, 20%, 69%);
    --verde04:          hsl(130, 20%, 76%);
    --verde03:          hsl(130, 20%, 83%);
    --verde02:          hsl(130, 20%, 90%);
    --verde01:          hsl(120, 15%, 95%);
    --grigio01:         rgb(224, 224, 224);
}

.rotate90toLeft {
    transform:      rotate(90deg);
}

.rotate180 {
    transform:      rotate(180deg);
}

.flipX {
    transform:      scaleX(-1);;
}

table.cubeTable {
    background-color:   white;
    border-collapse:    separate;
    text-indent:        initial;
    border-spacing:     0;
    font-size:          0.75rem;
    line-height:        1;
    font-family:        "Roboto", "Helvetica", "Arial", sans-serif;
}

.cubeTable th,
.cubeTable td {
    min-height:         29px !important;
    height:             29px !important;
    max-height:         29px !important;
 /* border:             1px solid var(--grigio01); */
    border-right:       0;
    border-bottom:      0;
    white-space:        pre;
}
.cubeTable tbody th,
.cubeTable td {
    box-shadow:         inset 1px 0 0 0 var(--grigio01), inset 0 -1px 0 0 var(--grigio01) !important;
}

.cubeTable th {
    padding:            0 10px 0 10px;
}

.cubeTable td,
.cubeTable tr:not(:first-of-type) th.measure {
    padding:            0 10px 0 0;
}
/*
.cubeTable thead th {
    box-shadow:         inset 0 -1px 0 0 var(--grigio01) !important;
    border-bottom:      1px solid var(--grigio01);
}
*/
.cubeTable td,
.cubeTable th {
    border-left-width:  0;
    border-right-width: 0;
    cursor:             default;
}

.cubeTable td {
    width:              150px;
}

/* thead */

.cubeTable thead th {
    font-size:          0.85rem;
    background-color:   #f0f4f0;
    cursor:             default;
}

.cubeTable thead th.measure {
    color:              #509556;
}

.cubeTable thead th.dimension {
    text-align:         left;
}
.cubeTable thead th.measure {
    text-align:         right;
}

.cubeTable thead th.measure[colspan] {
    text-align:         left;
}


/* tbody */

.cubeTable tbody th {
    text-align:         left;
    font-weight:        normal;
}



.cubeTable tbody td {
    text-align:         right;
}

.cubeTable.tabularMode tbody tr th,
.cubeTable.tabularMode tbody tr td,
.cubeTable             tbody tr.grandTotal th,
.cubeTable             tbody tr.grandTotal td {
    cursor:             default;
}

/* STICKY - inizio */
.cubeTable thead tr:nth-of-type(1) th,
.cubeTable thead tr:nth-of-type(1) td,
.cubeTable thead tr:nth-of-type(2) th,
.cubeTable thead tr:nth-of-type(2) td,
.cubeTable tbody tr.grandTotal     th,
.cubeTable tbody tr.grandTotal     td {
    position:           sticky;
    z-index:            100;
}/*
.cubeTable thead th:first-of-type,
.cubeTable tbody th:first-of-type {
    position:           -webkit-sticky;
    position:           sticky;
    left:               0;
    z-index:            101 !important;
    background-color:   #f0f4f0;
}*/
/*
.cubeTable tbody th:first-of-type {
    background-color:   white;
    z-index:            11 !important;
}*/
.cubeTable tbody tr.grandTotal th {
    position:           sticky;
    z-index:            996 !important;
}
.cubeTable thead tr:first-of-type th.measureStart {
    left:               0;
}
/* STICKY - fine */

.cubeTable thead tr:nth-of-type(1) th,
.cubeTable thead tr:nth-of-type(1) td {
    top:                0;
}

.cubeTable thead tr:nth-of-type(2) th,
.cubeTable thead tr:nth-of-type(2) td {
    top:                var(--altezzaRiga);
}

.cubeTable thead tr:nth-of-type(3) th,
.cubeTable thead tr:nth-of-type(3) td {
    top:                calc( var(--altezzaRiga) * 2 );
}

.cubeTable thead tr:nth-of-type(4) th,
.cubeTable thead tr:nth-of-type(4) td {
    top:                calc( var(--altezzaRiga) * 3 );
}

.cubeTable thead tr:nth-of-type(5) th,
.cubeTable thead tr:nth-of-type(5) td {
    top:                calc( var(--altezzaRiga) * 4 );
}

.cubeTable tbody tr.grandTotal th,
.cubeTable tbody tr.grandTotal td {
    font-weight:        bold;
    background-color:   #eeeeee;
}

.cubeTable tbody tr.grandTotal th,
.cubeTable tbody tr.grandTotal td {
    top:                calc( var(--altezzaRiga) * ( var(--numeroDimensioniPivot) + 2 ) );
}


.zero {
    color: rgb(211,211,211);
}

.subtotal .zero {
    color: rgb(181,181,181);
}

.grandTotal .zero {
    color: transparent;
}

.negative {
    color: #cc0000;
}

.cubeTable th.COLnumber,
.cubeTable thead th.firstMeaCalc {
    min-width:  155px;
}
/*
.cubeTable thead th.firstMeaCalc,
.cubeTable tbody td.firstMeaCalc {
    border-left: 2px solid var(--grigio01);
}
*/
.cubeTable th.COLdelta {
    min-width:  120px;
}

.cubeTable th.COLperc,
.cubeTable th.COLdeltaperc {
    min-width:  80px;
}

.cubeTable th.COLdelta     span,
.cubeTable th.COLperc      span,
.cubeTable th.COLdeltaperc span {
    display:    none; /* nascondo l'icona per l'ordinamento perchè per questi tipi di misure non si deve poter ordinare */
}

/*
.measureOdd {
    background-color: rgba( 0, 0, 0, 0.04 );
}

.measureEven {
    background-color: rgba( 0, 0, 0, 0.02 );
}
*/

.cubeTable th.dimension {
 /* border-right:       1px solid var(--grigio01) !important; */
    white-space:        pre;
    overflow:           hidden;
    text-overflow:      ellipsis;
}
/*
.cubeTable td.measure:first-of-type {
    border-left:        1px solid var(--grigio01) !important;
}

th.colDimensionStart,
td.colDimensionStart {
    border-left:        1px solid var(--grigio01);
}

th.measureStart,
td.measureStart {
    border-left:        2px solid rgb(204, 204, 204);
}
*/
.sortIcon {
    vertical-align:     middle;
    margin-left:        5px;
 /* margin-right:       5px; RIMOSSO per guadagnare spazio nelle intestazioni */
    width:              20px !important;
    height:             20px !important;
    color:              transparent;
    cursor:             pointer;
}

.cubeTable th:hover .sortIcon,
.cubeTable td:hover .sortIcon {
    color:              #585858;
}

.sortIcon.active[data-sort-direction="ASC"],
.sortIcon.active[data-sort-direction="DESC"] {
    color:              green;
}

.sortIcon:hover {
    color:              black !important;
}

.focusIcon {
    vertical-align:     text-bottom;
    margin-left:        -18px;
    margin-right:       1px;
    width:              16px !important;
    height:             16px !important;
    color:              green;
    cursor:             pointer;
}
.focusIcon:hover {
    color:              black !important;
}

/*
.subtotal0 { font-weight: bold !important; color: rgba(226, 226, 226, 1 ) !important;  background-color: rgba(85,   85,  85, 0.1 ) !important; }
.subtotal1 { font-weight: bold !important; color: rgba(198, 198, 198, 1 ) !important;  background-color: rgba(113, 113, 113, 0.1 ) !important; }
.subtotal2 { font-weight: bold !important; color: rgba(170, 170, 170, 1 ) !important;  background-color: rgba(141, 141, 141, 0.1 ) !important; }
.subtotal3 { font-weight: bold !important; color: rgba(141, 141, 141, 1 ) !important;  background-color: rgba(170, 170, 170, 0.1 ) !important; }
.subtotal4 { font-weight: bold !important; color: rgba(113, 113, 113, 1 ) !important;  background-color: rgba(198, 198, 198, 0.1 ) !important; }
.subtotal5 { font-weight: bold !important; color: rgba(85,   85,  85, 1 ) !important;  background-color: rgba(226, 226, 226, 0.1 ) !important; }
*/

.grandTotal th,
.grandTotal td {
              color: #191919;  background-color: #E1E1E1 !important; font-weight: bold !important;
}

/* !!! tenere sincronizzati con backend per excel !!! */
.subtotal1  { color: #2D2D2D;  background-color: #E6E6E6 !important; }
.subtotal2  { color: #313131;  background-color: #E7E7E7 !important; }
.subtotal3  { color: #353535;  background-color: #E8E8E8 !important; }
.subtotal4  { color: #393939;  background-color: #E9E9E9 !important; }
.subtotal5  { color: #3D3D3D;  background-color: #EAEAEA !important; }
.subtotal6  { color: #414141;  background-color: #EBEBEB !important; }
.subtotal7  { color: #454545;  background-color: #ECECEC !important; }
.subtotal8  { color: #494949;  background-color: #EDEDED !important; }
.subtotal9  { color: #4D4D4D;  background-color: #EEEEEE !important; }
.subtotal10 { color: #515151;  background-color: #EFEFEF !important; }
.subtotal11 { color: #555555;  background-color: #F0F0F0 !important; }
.subtotal12 { color: #595959;  background-color: #F1F1F1 !important; }
.subtotal13 { color: #5D5D5D;  background-color: #F2F2F2 !important; }
.subtotal14 { color: #616161;  background-color: #F3F3F3 !important; }
.subtotal15 { color: #656565;  background-color: #F4F4F4 !important; }
.subtotal16 { color: #696969;  background-color: #F5F5F5 !important; }
.subtotal17 { color: #6D6D6D;  background-color: #F6F6F6 !important; }
.subtotal18 { color: #717171;  background-color: #F7F7F7 !important; }
.subtotal19 { color: #757575;  background-color: #F8F8F8 !important; }
.subtotal20 { color: #797979;  background-color: #F9F9F9 !important; }
.subtotal21 { color: #7D7D7D;  background-color: #FAFAFA !important; }
.subtotal22 { color: #818181;  background-color: #FBFBFB !important; }
.subtotal23 { color: #858585;  background-color: #FCFCFC !important; }
.subtotal24 { color: #898989;  background-color: #FDFDFD !important; }
.subtotal25 { color: #8D8D8D;  background-color: #FEFEFE !important; }
.subtotal26 { color: #919191;  background-color: #FFFFFF !important; }

.subtotprevious {
    background-color:   white !important;
    border-color:       white !important;
}

.cubeTable.subtotalsEnabled tbody tr:not(.subtotal):not(.grandTotal):not(.rowSelected) th:not(:last-of-type) {
    background-color:   white !important;
    border-top-color:   white !important;
    border-bottom-color:white !important;
}

.cubeTable.subtotalsEnabled tbody tr                th {
    color:              transparent;
}

.cubeTable                  tbody tr.subtotal       th, 
.cubeTable                  tbody tr.subtotal       td {
    font-weight:        bold !important;
}

.cubeTable                  tbody tr                th.showValue,
.cubeTable                  tbody tr:not(.subtotal) th:last-of-type,
.cubeTable.showAllValues    tbody tr                th               {
    color:              black;
}
/* linea che divide le colonne bloccate a sinistra da quelle a destra */
.cubeTable thead th.first.dimension,
.cubeTable tbody th:last-of-type
{
    box-shadow: inset -1px 0 0 0 var(--verde06 ), inset 1px 0 0 0 var(--grigio01), inset 0 -1px 0 0 var(--grigio01) !important;
}


.cubeTable                  tbody tr.subtotal       th,
.cubeTable                  tbody tr.subtotal       td {
    position:           sticky;
    top:                calc( var(--altezzaRiga) * ( var(--numeroDimensioniPivot) + 3 ) );
}
.cubeTable                  tbody tr.rowSelected    th,
.cubeTable                  tbody tr.rowSelected    td {
    position:           sticky;
    top:                calc( var(--altezzaRiga) * ( var(--numeroDimensioniPivot) + 3 ) );
    bottom:             0;
}
.cubeTable.subtotalsEnabled tbody tr.rowSelected    th,
.cubeTable.subtotalsEnabled tbody tr.rowSelected    td {
    top:                calc( var(--altezzaRiga) * ( var(--numeroDimensioniPivot) + 3 + 1 ) );
}

.cubeTable                  tbody tr.rowSelected    th {
    z-index:            999 !important;
}
.cubeTable                  tbody tr.rowSelected    td {
    z-index:            998 !important;
}
.cubeTable                  tbody tr.subtotal       th {
    z-index:            994 !important;
}
.cubeTable                  tbody tr.subtotal       th.showValue {
    z-index:            995 !important;
}
.cubeTable                  tbody tr.subtotal       td {
    z-index:            993 !important;
}

.cubeTable                  tbody tr:hover td,
.cubeTable                  tbody tr:hover th,
.cubeTable                  tbody tr.rowSelected td,
.cubeTable                  tbody tr.rowSelected th
{
    background-color:   #f8f8f8 !important;
    color:              black !important;
}

.cubeTable                  tbody tr             td.negative,
.cubeTable                  tbody tr:hover       td.negative,
.cubeTable                  tbody tr.rowSelected td.negative{
    color:              #cc0000 !important;
}

.cubeTable                  tbody tr.rowSelected th,
.cubeTable                  tbody tr.rowSelected td {
    background-color:   #d7e5d8 !important;
}

.cubeTable                  tbody th.drillDown {
    cursor:             pointer !important;
}

.cubeTable                  tbody th.drillDown:hover {
    text-decoration:    underline;
}

.cubeTable                  tbody tr.rowSelected th.drillDown {
    text-decoration:    underline;
}

.cubeTable                  tbody th.drillDown:hover {
    color:              grey !important;
}

.cubeTable                  tbody tr.grandTotal th {
    color:              black !important;
}

.cubeTable                  tbody tr.grandTotal td.COLperc {
 /* color:              transparent; commentato per rendere di nuovo visibile il 100% del grand total */
}

.cubeTable                  td.measure.\<ROW.TOTAL\> {
    background-color:   rgba(246, 246, 246, 1 );
}

.cubeTable                  .measure.\<ROW.TOTAL\> > span {
    display:            none;
}

.horizontalSortIcon {
    position:   absolute;
    left:       -30px;
    transform:  rotate(90deg);
    color:      green;
}

.cubeTable       th {
    position:           sticky;
    background-color:   white;
}
/* thead */
.cubeTable thead th.dimension {
    z-index:            999 !important;
}
.cubeTable thead th:not(.dimension) {
    z-index:            997 !important;
}
.cubeTable thead th:not(.dimension).first {
    z-index:            998 !important;
}
/* tbody */
.cubeTable tbody th.dimension {
    z-index:            992 !important;
}
/* esempi di regole per sticky fino a 10 dimensioni:
.cubeTable.colsLocked th:nth-of-type( 1) { left: 0 !important; }
.cubeTable.colsLocked th:nth-of-type( 2) { left: var(--Col01) !important; }
.cubeTable.colsLocked th:nth-of-type( 3) { left: calc( var(--Col01) + var(--Col02) ) !important; }
.cubeTable.colsLocked th:nth-of-type( 4) { left: calc( var(--Col01) + var(--Col02) + var(--Col03) ) !important; }
.cubeTable.colsLocked th:nth-of-type( 5) { left: calc( var(--Col01) + var(--Col02) + var(--Col03) + var(--Col04) ) !important; }
.cubeTable.colsLocked th:nth-of-type( 6) { left: calc( var(--Col01) + var(--Col02) + var(--Col03) + var(--Col04) + var(--Col05) ) !important; }
.cubeTable.colsLocked th:nth-of-type( 7) { left: calc( var(--Col01) + var(--Col02) + var(--Col03) + var(--Col04) + var(--Col05) + var(--Col06) ) !important; }
.cubeTable.colsLocked th:nth-of-type( 8) { left: calc( var(--Col01) + var(--Col02) + var(--Col03) + var(--Col04) + var(--Col05) + var(--Col06) + var(--Col07) ) !important; }
.cubeTable.colsLocked th:nth-of-type( 9) { left: calc( var(--Col01) + var(--Col02) + var(--Col03) + var(--Col04) + var(--Col05) + var(--Col06) + var(--Col07) + var(--Col08) ) !important; }
.cubeTable.colsLocked th:nth-of-type(10) { left: calc( var(--Col01) + var(--Col02) + var(--Col03) + var(--Col04) + var(--Col05) + var(--Col06) + var(--Col07) + var(--Col08) + var(--Col09) ) !important; }
*/

 .cubeTable thead th
,.cubeTable tbody td {
    padding-left: calc( var(--numeroDimensioniPivot) * 5px + 10px );
}

/*
.first {
    border-left:        1px solid lightgrey !important;
}
*/
#cubeTable:not(.showAllValues) thead tr:not(:last-of-type) th:not(.first) {
    color:              transparent;
}

#cubeTable thead tr:not(:last-of-type) th.first {
    box-shadow:         inset 1px 0 0 0 var(--verde06);
}

#cubeTable thead th {
    text-align:         left;
    overflow-x:         hidden;
    white-space:        pre;
    text-overflow:      ellipsis;
}

/* colori per intestazioni di colonne (sfumature di verde) */
#cubeTable thead tr:nth-last-of-type(5) th { background-color: var(--verde05); border: 0; }
#cubeTable thead tr:nth-last-of-type(4) th { background-color: var(--verde04); border: 0; }
#cubeTable thead tr:nth-last-of-type(3) th { background-color: var(--verde03); border: 0; }
#cubeTable thead tr:nth-last-of-type(2) th { background-color: var(--verde02); border: 0; }
#cubeTable thead tr:nth-last-of-type(1) th { background-color: var(--verde01); border: 0; } /* #519657; color: white; */


#cubeTable thead tr:last-of-type th {
    text-align:         right;
}
#cubeTable thead tr:last-of-type th.dimension {
    text-align:         left;
}
/*
#cubeTable thead tr:last-of-type th {
    border-top:     1px solid green;
    border-bottom:  1px solid green;
}
*/
#cubeTable thead th.dimension {
    text-align:         right;
}
#cubeTable thead th.dimension.PIVOT .sortIcon {
    color:              green !important;
    transform:      rotate(90deg);
}

.chip-wrapper div.sort-pivot-button {
    position:           absolute;
    color:              #616161;
    background-color:   rgba(255,255,255,0.65);
    border:             1px solid #c8a415;
    top:                3px;
    right:              33px;
    width:              27px;
    height:             27px;
    border-radius:      5px;
    transition:         transform .1s;
    cursor:             pointer;
    z-index:            100;
    text-align:         center;

}
.chip-wrapper div.sort-pivot-button span {
    display:            inline-block;
    position:           relative;
    top:                2px;
    font-weight:        bold;
    font-size:          16px;
}
.chip-wrapper div.sort-pivot-button button {
    padding:            1px;
    min-width:          0;
    height:             26px;
    width:              26px;
}
.chip-wrapper div.sort-pivot-button svg {
    position:           relative;
}
.chip-wrapper div.sort-pivot-button svg.green {
    color:              green;
}
.chip-wrapper div.sort-pivot-button svg.grey {
    color:              #999999;
}

.smalldec {
    font-size: 85%;
    opacity: 0.85;
}