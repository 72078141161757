/* ----- STILI PER BACKGROUND STANDARD SYSTRATEGY ----- */
.background-with-logo {
    display:                block;
    position:               fixed;
    top:                    -76%;
    left:                   -100%;
    z-index:                -1;
    height:                 250%;
    width:                  250%;
    background-image:        url('systrategy_cube_logo.svg');
    background-repeat:      no-repeat;
    background-size:        contain;
    background-position:    center;
    background-origin:      content-box;
    filter:                 opacity(20%) grayscale(0%) saturate(100%) blur(3px);
}
.background-with-logo-top-dx {
    display:                block;
    position:               fixed;
    top:                    -61%;
    left:                   -5%;
    z-index:                -1;
    height:                 100vh;
    width:                  100vw;
    background-image:       url('systrategy_cube_logo_top_dx.svg');
    background-repeat:      no-repeat;
    background-size:        contain;
    background-position:    center;
    background-origin:      content-box;
    filter:                 opacity(15%) grayscale(0%) saturate(100%) blur(3px);
}
.background-with-logo-top-dx-bis {
    display:                block;
    position:               fixed;
    top:                    -82%;
    left:                   -8%;
    z-index:                -1;
    height:                 115vh;
    width:                  115vw;
    background-image:       url('systrategy_cube_logo_top_dx.svg');
    background-repeat:      no-repeat;
    background-size:        contain;
    background-position:    center;
    background-origin:      content-box;
    filter:                 opacity(15%) grayscale(0%) saturate(100%) blur(3px);
}
.background-with-logo-bot-dx {
    display:                block;
    position:               fixed;
    top:                    60%;
    left:                   -5%;
    z-index:                -1;
    height:                 100vh;
    width:                  100vw;
    background-image:       url('systrategy_cube_logo_bot_dx.svg');
    background-repeat:      no-repeat;
    background-size:        contain;
    background-position:    center;
    background-origin:      content-box;
    filter:                 opacity(10%) grayscale(0%) saturate(100%) blur(3px);
}
.background-with-logo-bot-dx-bis {
    display:                block;
    position:               fixed;
    top:                    67%;
    left:                   -8%;
    z-index:                -1;
    height:                 115vh;
    width:                  115vw;
    background-image:       url('systrategy_cube_logo_bot_dx.svg');
    background-repeat:      no-repeat;
    background-size:        contain;
    background-position:    center;
    background-origin:      content-box;
    filter:                 opacity(10%) grayscale(0%) saturate(100%) blur(3px);
}

/* --------------- STILI LOGIN --------------- */

.login {
    height:                 100%;
    padding:                0;
    margin:                 0;
    display:                flex;
    align-items:            center;
    justify-content:        center;
}

.login .login-container {
    
    max-width:              550px;
    margin:                 0 auto;
    animation-fill-mode:    none !important;
    animation-duration:     .3s;
    text-align:             center;
    font-size:              14px;
    overflow:               hidden;
    box-sizing:             border-box;

    /* stili standard login systrategy */
    display:                flex;
    position:               absolute;
    top:                    50%;
    left:                   50%;
    transform:              translate(-50%, -50%);
    height:                 400px;
    width:                  550px;
    border-radius:          6px;
    box-shadow:             -1px 1px 5px 1px rgb(0 0 0 / 15%);
    background:             white;
    
}
/*
.login .only-logo {
    display:                flex!important;
    align-items:            center!important;
    justify-content:        center!important;
    -webkit-box-align:      center !important;
    -webkit-box-pack:       center !important;
    box-sizing:             border-box;
    width:                  40%;
    order:                  2;
}

.login .only-logo img {
    width:                  200px;
}
*/




.login-container .only-logo       { background-color: #519657;        }
.login-container .only-logo.local { background-color: lightsteelblue; }
.login-container .only-logo.dev   { background-color: lightsalmon;    }
.login-container .only-logo.stage { background-color: lightseagreen;  }

.environment       {
    display:                none;
    text-align:             center;
    margin:                 0;
    font-family:            "Prosto One",cursive;
    color:                  black;
    top:                    44px;
    letter-spacing:         2px;
    user-select:            none;
}

.environment.local,
.environment.dev,
.environment.stage {
    display:                inline-block;
}

h1.environment {
    top:                    15px !important;
}

.environment.cliente-cod,
.environment.cliente-desc {
    top:                    62px !important;
}




.login-container .side {
    position:               relative;
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-end;
}
.login-container .side.left {
    height:                 100%;
    width:                  330px;
    border-top-left-radius:     5px;
    border-bottom-left-radius:  5px;
}
.login-container .side.right {
    height:                 100%;
    width:                  220px;
    border-top-right-radius:    5px;
    border-bottom-right-radius: 5px;
    
    align-items:            center !important;
    justify-content:        center !important;
    box-sizing:             border-box;
    order:                  2;

}

.login-container .top-bottom-section {
    height:                 110px;
}

.login-container .header-product-name {
    position:               relative;
}
.login-container .header-product-name > div {
    position:               relative;
}

.login-container .header-product-name .ms-info-login {
    position:               relative;
    /*width:                  280px;*/
    margin-left:            auto;
    margin-right:           auto;
}

.login-container .user-psw-container {
    position:               relative;
    margin:                 0 auto;
    border:                 0;
    width:                  85%;
}

.login-container .side.right .database-info {
    position:               relative;
    color:                  #e7e7e7;
    top:                    2px;
}


.login-container .user-psw-container .logo.systrategy {
    width:                  150px;
}

.login-container .user-psw-container .error {
    position:               absolute;
    top:                    145px;
    left:                   0;
}

.login-container .user-psw-container .input-container {
    display:                inline-flex;
    border-bottom:          1px solid #1a506a;
    padding-bottom:         3px;
    margin-bottom:          20px;
}

.login-container .user-psw-container .input-container i {
    height:                 22px;
    width:                  22px;
    font-size:              22px;
}

.login-container .footer-info {
    display:                flex;
    position:               relative;
    width:                  100%;
    flex-direction:         row;
    align-items:            flex-end;
    justify-content:        space-between;
}
.login-container .footer-info a,
.login-container .footer-info a img {
    height:                 35px;
}

.user-psw-container .login-button {
    display:                flex;
    /*width:                  280px;*/
    height:                 40px;
    border:                 1px solid;
    transition:             background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family:            inherit;
    font-weight:            500;
    line-height:            1;
    border-radius:          4px;
    letter-spacing:         0.02857em;
    text-transform:         uppercase;
    margin:                 0 auto;
    align-items:            center;
    justify-content:        center;
}
.user-psw-container .login-button input:hover{
    cursor:                 pointer;
}

.user-psw-container input {
    padding-left:           5px;
}
.user-psw-container input[type="submit"] {
    font-weight:            bold;
    background-color:       white;
    cursor:                 pointer;
    text-align:             center;
    padding-left:           0;
    height:                 40px;
    /*width:                  280px;*/
    border-radius:          4px;
}
.user-psw-container input::placeholder {
    color:                  #a9a9a9;
}

.login .login-content {
    padding:                0;
    width:                  60%;
    order:                  1;
    display:                flex;
}

.login .login-outer-container {
    padding:                10px 25px;
    text-align:             left;
}

.login .login-outer-container .form-group{
    width:                  100%;
    margin-top:             15px;
}

.login .login-outer-container .login-credentials-wrapper {
    margin-bottom:          10px;
    width:                  100%;
}

.login .forgot-credentials-link {
    margin-top:             10px;
    width:                  100%;
    visibility:             hidden;
}
.login .forgot-credentials-link a{
    color:                  #519657;
    font-size:              0;
}

.login .login-btn {
    margin-top:             18px;
    margin-bottom:          10px;
    font-size:              16px;
    border:                 1px solid;
    width:                  100%;
}

@media screen and (max-width: 575px) {

    .login .login-content {
        width:              100%;
        order:              2;
    }

    .login .green {
        width:              100%;
    }

    .login  .logo-content {
        width:              100%;
        order:              1;
    }
}

.login .edition,
.login .database-info {
    font-size:              14px !important;
    font-family:            "Prosto One",cursive !important;
    color: #2d2d2d !important;
}

.login .version-number > span {
    font-size:              12px !important;
}

.login .roobeek-logo {
    margin-top:             25px;
    margin-left:            25px;
    width:                  100%;
}

.login .info-top-bottom {
    height:                 55px;
    width:                  100%;
}

.login .info-bottom > div > span {
    display:                block;
}

.login .edition {
    position:               absolute;
    width:                  220px;
    bottom:                 7px;
    left:                   50%;
    transform:              translate(-50%);
}
