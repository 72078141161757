.dashboard{
    position:           relative;
    display:            inline-flex;
    height:             100%;
    width:              100%;
    flex-wrap:          wrap;
}
.dashboard .title{
    margin-top:         10px;
}
.dashboard .chart-wrapper{
    display:            flex;
    position:           relative;
    padding-left:       20px;
    padding-bottom:     50px;
}

.dashboard .chart-wrapper.unique {
    height:             calc( 100vh - 200px ) ;
}
.dashboard .chart-wrapper.unique .chart{
    width:              97%;
}

.dashboard .chart-wrapper.top{
    height:             calc( 50vh - 60px ) ;
}

.dashboard .chart-wrapper .chart{
    width:              100%;
}
.dashboard .chart-wrapper.bottom{
    display:            flex;
    height:             calc( 50vh - 60px ) ;
}
.dashboard .chart-wrapper.bottom .chart{
    position:           relative;
    width:              50%;
}
.dashboard .chart-wrapper.bottom .chart .title{
    position:           absolute;
    margin-left:        auto;
    margin-right:       auto;
    left:               0;
    right:              0;
}
.dashboard .chart-wrapper.bottom .chart .pie-tooltip{
    background-color:   white;
    padding:            5px;
    border-radius:      3px;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow:    0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow:         0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.dashboard .chart-wrapper.bottom .chart .pie-tooltip .value{
    font-weight:        bold;
}
.dashboard .chart-toggle{
    display:            none;
    position:           absolute;
    right:              10px;
    top:                10px;
}


.dashboard #logo1 {
    position:               absolute;
    top:                    0;
    left:                   0;
    z-index:                1;
    background-image:       url("roobeek_marchio.svg");
    opacity:                0.1;
    filter:                 grayscale(50%);
    height:                 100%;
    width:                  100%;
    background-repeat:      no-repeat;
    background-size:        cover;
    background-position-y:  center;
    transform:              scale(1.3);
}

.dashboard .logo_container {
    display:                flex;
    width:                  100%;
    justify-content:        center;
    align-items:            center;
    align-content:          center;
    flex-wrap:              wrap;
    opacity:                1;
    filter:                 grayscale(0);
    overflow:               hidden;
}

.dashboard .logo_container > div {
    width:                  670px;
    text-align:             center;
}

.dashboard .dashboard-container {
    display:                flex;
    height:                 100%;
    width:                  100%;
}
