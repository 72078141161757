.change-password{
    background-color:   white;
    padding:            0 20px 20px 20px;
}
.change-password .row{
    max-width:          400px;
}
.change-password .submit-buttons{
    display:            flex;
    justify-content:    center;
}
.change-password .password-wrapper,
.change-password  .submit-buttons .button-space {
    flex-grow:          1;
}
.change-password .submit-buttons .button{
}
.change-password .row.legend{
    font-size:          13px;
    font-style:         italic;
    margin-top:         20px;
}
.change-password .separator{
    width:              100%;
    height:             20px;
    border-bottom:      1px solid #dddddd;
}
.change-password .row{
    margin-bottom:      15px;
}

.change-password .password-wrapper input {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}