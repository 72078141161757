.filters.dialog > div > div {
    max-height: unset !important;
}

.filter-custom {
    position: relative;
}
{
    transition: all 1s ease-in;
}

.filters.dialog .filtertype.L.readonly .add-filter {
    display:            none;
}

.filter-custom .grid-wrapper {
    max-height:         calc(100vh - 214px);
    overflow-y:         auto;
    overflow-x:         hidden;
    margin-left:        0.1%;
    width:              99.8%;
}

.filter-custom .invisible {
    visibility: hidden;
}

.filter-custom table {
    border-spacing: 0;
}

.filter-custom table thead tr:first-of-type th {
    position:           -webkit-sticky;
    position:           sticky;
    z-index:            100;
    background-color:   white;
    top:                0;
}

.warning-limit {
    position:           -webkit-sticky;
    position:           sticky;
    z-index:            100;
    background-color:   rgb(254, 240, 196) !important;
    top:                39px;
}

.warning-limit td {
    padding-top:        10px;
    padding-left:       15px;
    padding-bottom:     10px;
}

.filter-custom th,
.filter-custom td {
    border-bottom: 1px solid rgb(224, 224, 224);
}

.filter-custom th {
    padding: 5px;
}

.filter-custom th > div {
    width:              450px !important;
    white-space:        pre;
    padding-left:       22px;
}
.filter-custom td > div {
    width:              538px !important;
    overflow-x:         hidden;
    white-space:        pre;
    text-overflow:      ellipsis;
}

.filter-custom th > div > span,
.filter-custom td > div > span {
    margin-right:       20px;
}

.filter-custom th {
    text-align: left;
}

.filter-custom tbody tr:nth-of-type(odd)  {
    background-color:   rgb(249, 249, 249);
}
.filter-custom tbody tr:nth-of-type(even)  {
    background-color:   rgb(253, 253, 253);
}

.filter-custom th:not(:first-of-type),
.filter-custom td:not(:first-of-type) {
    width: 100%;
}

.filter-custom td div {
    padding-left:   26px;
}
/*
.filter-custom .check > span {
    padding: 0;
}
.filter-custom .check > div {
    min-width:      79px;
}
*/

.filter-custom .expand-selected{
    display: inline-flex;
    margin: 8px;
    width: 25px;
}

.filter-custom .expand-selected i{
    cursor: pointer;
    font-size: 25px;
    color: #519657;
    vertical-align: middle;
    margin-right: 10px;
    transition: transform 0.04s;
}

.filter-custom .expand-selected i:hover{
    transform: scale(1.2);
}

.filter-custom .expand-selected.disabled i{
    cursor: default;
    color: #dddddd;
}

.filter-custom .expand-selected.disabled i:hover{
    transform: none;
}

.filter-custom .selected-items{
    position: relative;
    padding: 0;
    margin-bottom: 10px;
    overflow: hidden;
    height: 40px;
    display: inline-flex;
    width: 99.8%;
    margin-left: 0.1%;
    margin-top: 1px;
}

.filter-custom .selected-items.showAll {
    flex-wrap: wrap;
    height: auto;
}

.filter-custom .selected-items-over{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    position: absolute;
    z-index: 10000;
    overflow-y: auto;
}

.filter-custom .base-item{
    position: relative;
    height: 0;
}

.filter-custom .base-item-over{
    position: relative;
    height: 40px;
    margin-bottom: 10px;
}

.filter-custom .section-label{
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.87);
    line-height: 25px;
    font-size: 0.875rem;
}

.filter-custom .section-label .warningIcon {
    margin:         0;
    padding:        0;
    color:          Orange;
    position:       relative;
    top:            6px;
}

.filter-custom .select-all span{
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer;
    color: #519657;
    margin-right: 20px;
}

.filter-custom .add-filter svg {
    vertical-align:     bottom;
    margin-bottom:      13px;
    cursor:             pointer;
}
/*
.include,
.exclude {
    line-height: 25px;
    font-size:   0.875rem;
    width:       50px;
    display:     inline-block;
}
*/
.include.checked,
.exclude.checked {
    font-weight: bold;
}

.myswitch > span:nth-child(2) {
    background-color: lightgrey !important;
    opacity: 1 !important;
}

.chip {
    margin: 4px;
}

.chip:nth-child(3) {
    max-width: 310px;
}

.chip:nth-child(n+4) {
    max-width: 542px;
}

.not-available {
    background-color: rgb(254, 240, 196) !important;
}

.selected-items-label {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 6px;
}

.selected-items-label span {
    font-size: 14px;
    display: inline-block;
}
.selected-items-label > span {
    min-width: 184px;
}
.selected-items-label > span > span:first-of-type {
    font-weight: bold;
}

#filter-input {
    font-size: 13px;
}

.filter-custom .check-all-filter-row .invisible {
    visibility: hidden;
}
