.MuiDrawer-paperAnchorDockedLeft {
    overflow-x:     hidden !important;
}
.powered-by {
    margin:         0 !important;
    padding:        0 !important;
}

.bottom-logo {
    padding-bottom: 0 !important;
}

.powered-by img.logo {
    width:          200px;
    margin:         0 !important;
    padding:        0 !important;
}

.openDrawerIcon {
    margin-right:   0 !important;
    margin-left:    0 !important;
}

ul.MuiList-root.bottom-logo.MuiList-padding:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 11px;
    height: 80px;
    background-color: rgb(245,245,245);
}

#root > div.main > div > div > div > div:nth-child(2) > div:nth-child(1) {
    margin-right: 0 !important;
}
