.cubes-settings {
    margin-top: 20px;
}

.cubes-settings td, .cubes-settings th {
    font-size: 14px;
    padding: 10px;
    vertical-align: middle;
}

.bold {
    font-weight: bold;
}

.cubes-settings .main{
    padding: 20px;
}

.cubes-settings .grid-parent{
    display: flex;
}

.cubes-settings .grid-parent .grid-child{
    padding-right: 30px;
    min-height: 800px;
}

.cubes-settings .grid-parent .grid-child.left{
    border-right: 1px solid #519657;
}

.cubes-settings .grid-parent .grid-child.right{
    width: 100%;
    padding-top: 10px;
    background-color: #fbfbfb;
    padding-left: 25px;
}

.cubes-settings .priv-cell{
    border-right: 1px solid #dddddd;
    width: 250px;
}

.cubes-settings .check-cell{
    width: 50px;
    background-color: #b8ddb8;
    color: green;
    cursor: pointer;
}

.cubes-settings .check-cell:hover {
    background-color: #a6c6a6;
}
.cubes-settings .block-cell{
    width: 50px;
    background-color: #e7bdbd;
    color: #be0000;
    cursor: pointer;
}

.cubes-settings .block-cell:hover {
    background-color: #caa5a5;
}

.cubes-settings .icon-link-cell{
    cursor: pointer;
}
.cubes-settings .icon-link-cell svg{
    color: #519657;
}

.cubes-settings .icon-link-cell:hover svg{
    filter: brightness(75%);
}

.cubes-settings .cubes-list {
    width:  230px !important;
}

.cubes-settings .cubes-list thead tr:first-of-type {
    background-color: rgb(184, 221, 184, 0.2);
}

.cubes-settings .cubes-list th,
.cubes-settings .cubes-list td {
    color: rgb(94, 94, 94);
}

.cubes-settings .row:hover {
    cursor: default;
    background-color: rgba(0,0,0,0.05);
}

.cubes-settings svg {
    cursor: pointer;
    vertical-align: middle;
}

.cubes-settings .row.selected {
    background-color: #c8e6c9;
}

.cubes-settings .check-selected{
    background-color: #c8e6c9;
}

.cubes-settings td svg{
    vertical-align: middle;
}

.cubes-settings .cell-user.disabled{
    background-color: #fbe9e0;
    color: #ce4040;
}   

.cubes-settings .check-selected svg{
    color: #519657;
}

.cubes-settings .check-not-selected{
    background-color: #f7f7f7;
}

.cubes-settings .check-not-selected svg{
    color: #ffffff;
}

.cubes-settings .check-not-selected:hover{
    background-color: #f7f7f7;
}

.cubes-settings .check-not-selected:hover svg{
    color: #519657;
}

.cubes-settings .selected-group{
    color: #519657;
    font-weight: bold;
}

.cubes-settings .select-all {
    display: block;
}

.cubes-settings .select-all span {
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer;
    color: #519657;
    margin-right: 20px;
}

.cubes-settings .outer-container {
    display: inline-flex;
    flex-direction: column;
}
/*
.cubes-settings .tab-panel-dimensions-measures {
    display: flex;
    flex-direction: row;
}*/

.cubes-settings .body-structure > div {
    display: inline-block;
    padding: 10px;
    vertical-align: top;
}

.cubes-settings .body-structure > div:nth-of-type(n+2) {
    max-width:  calc( 50%   - 125px );
    height:     calc( 100vh - 100px );
}


.cubes-settings .body-structure .SimpleTable-wrapper {
    max-height: calc( 100vh - 120px );
    /* overflow: scroll; */
}

.cubes-settings .DESCRIPTION {
    max-width: 70px;
}

.cubes-settings .SimpleTable table {
    width: 100%;
}

.editing-dialog .container {
    display:            flex;
    align-items:        center;
}

.editing-dialog .checkbox {
    margin-left:        -12px;
}

.editing-dialog .inputLabel {
    color:              rgb(70,70,70) !important;
}

.editing-dialog .FLAG_STARTUP.select {
    max-width: 50px;
}
.editing-dialog .FLAG_STARTUP.container label {
    font-size: 0.7rem;
}

.editing-dialog .DESCR.textfield > div {
    max-width: 160px;
}