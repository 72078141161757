.loading-shading-mui {
    position:   absolute;
    top:        0;
    left:       0;
    width:      100%;
    height:     100%;
    z-index:    99999;
    animation-name:     fadein;
    animation-duration: .5s;
    animation-delay:    .4s;
    background: rgba(255, 255, 255, .4);
}

@keyframes fadein {
    0%   { background: rgba(255, 255, 255, 0 ); }
    100% { background: rgba(255, 255, 255, .4); }
}

.loading-icon-mui {
    position:   absolute;
    font-size:  20px;
    top:        calc(50% - 20px);
    left:       calc(50% - 20px);
} 