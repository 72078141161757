.user-groups{
    margin-top: 20px;
}

.user-groups td, .user-groups th {
    font-size: 14px;
    padding: 7px;
}
.user-groups .main{
    padding: 20px;
}

.user-groups .grid-parent{
    display: flex;
}

.user-groups .grid-parent .grid-child.left {
    padding-right: 30px;
}

.user-groups .grid-parent .grid-child.right{
    border-left: 1px solid #519657;
    border-bottom: 1px solid #519657;
}

.user-groups .grid-parent .grid-child.right{
    padding-top: 10px;
    background-color: #fbfbfb;
    padding-left: 25px;
}

.user-groups .priv-cell{
    border-right: 1px solid #dddddd;
    width: 250px;
}

.user-groups .check-cell{
    width: 50px;
    background-color: #b8ddb8;
    color: green;
    cursor: pointer;
}

.user-groups .check-cell:hover {
    background-color: #a6c6a6;
}
.user-groups .block-cell{
    width: 50px;
    background-color: #e7bdbd;
    color: #be0000;
    cursor: pointer;
}

.user-groups .block-cell:hover {
    background-color: #caa5a5;
}

.user-groups .icon-link-cell{
    cursor: pointer;
}
.user-groups .icon-link-cell svg{
    color: #519657;
}

.user-groups .icon-link-cell:hover svg{
    filter: brightness(75%);
}

.user-groups .row{
    cursor: pointer;
}

.user-groups .row.selected {
    background-color: #c8e6c9;
}

.user-groups .check-selected{
    background-color: #c8e6c9;
}

.user-groups td svg{
    vertical-align: middle;
}

.user-groups .cell-user.disabled{
    background-color: #fbe9e0;
    color: #ce4040;
}   

.user-groups .check-selected svg{
    color: #519657;
}

.user-groups .check-not-selected{
    background-color: #f7f7f7;
}

.user-groups .check-not-selected svg{
    color: #ffffff;
}

.user-groups .check-not-selected:hover{
    background-color: #f7f7f7;
}

.user-groups .check-not-selected:hover svg{
    color: #519657;
}

.user-groups .selected-group{
    color: #519657;
    font-weight: bold;
}

.user-groups .select-all {
    display: block;
}

.user-groups .select-all span {
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer;
    color: #519657;
    margin-right: 20px;
}

.user-groups .outer-container {
    display: inline-flex;
    flex-direction: column;
}
/*
.user-groups .tab-panel-dimensions-measures {
    display: flex;
    flex-direction: row;
}*/

.user-groups .users-dimensions-measures > div {
    overflow-y: scroll;
    max-height: calc( 100vh - 250px );
    margin-top: 3px;
}