:root {
    --text-outline: -1px -1px 0 white,
                     1px -1px 0 white,
                    -1px  1px 0 white,
                     1px  1px 0 white;
}

.graph.grafico {
    padding:            5px;
}

.graph-tooltip {
    table-layout:       fixed;
    background-color:   white;
    border:             1px solid grey;
    padding:            5px;
    max-width:          200px;
}

.graph-tooltip-row td {
    word-wrap:          break-word;
}

.graph-tooltip .graph-tooltip-row span {
    min-width:          200px !important;
    width:              200px;
}

.graph.grafico > div > div > svg > g > g:nth-of-type(4) > g > text {
    transform: rotate(-45deg) translate(100px, 0) ;
}

.graph .graph-tooltip-value {
    font-weight: bold;
}

.graph #selectMeasureContainer {
    position:   absolute;
    top:        20px;
    left:       20px;
    z-index:    1000;
}

.graph #selectMeasureContainer > div > div {
    font-weight: bold;
}

.graph #legendLabel {
    position:   absolute;
    top:        20px;
    right:      20px;
    z-index:    99;
}
.graph #legendLabel > span:nth-of-type(1) {
    font-weight: normal;
    font-size:   12px;
    margin-right: 5px;
    color: grey;
    text-shadow: var(--text-outline) !important;
}
.graph #legendLabel > span:nth-of-type(2) {
    font-weight: bold;
    font-size:   14px;
    text-shadow: var(--text-outline) !important;
}

.graph #legendX {
    position:   absolute;
    bottom:     0;
    left:       50%;
    transform:  translateX(-50%);
    z-index:    99;
}

.graph #legendX > span:nth-of-type(1) {
    font-weight: bold;
    font-size:   14px;
    margin-right: 5px;
    text-shadow: var(--text-outline) !important;
}
.graph #legendX > span:nth-of-type(2) {
    font-weight: normal;
    font-size:   12px;
    color: grey;
    text-shadow: var(--text-outline) !important;
}

text {
    text-shadow: var(--text-outline) !important;
}
