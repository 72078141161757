:root {
    --numeroDimensioniPivot: 1;
    --altezzaSezionePivot:   calc( 19px + ( 40px * var(--numeroDimensioniPivot) ) );
}

.chip-wrapper {
    position:           relative;
    background-color:   #d7e5d8;
    cursor:             grab;
    border-radius:      5px;
    padding:            8px 0 7px 0;
    text-align:         left;
    width:              200px;
    height:             35px;
    font-size:          12px;
    -webkit-filter:     brightness(100%);
    margin:             10px 10px 0 10px;
    border:             1px solid #aeaeae;
    z-index:            1201;
    /*
    transition:         width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
                        margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    */
    overflow:           hidden;
    white-space:        pre;
    text-overflow:      ellipsis;
    color:              black;
    user-select:        none;
}

#body-measures   .filters-row,
#body-dimensions .filters-row {
    padding-bottom:     15px;
}
.layouts-container .chip-wrapper{
    cursor:             default;
}
.grafici-container .chip-wrapper{
    cursor:             pointer;
}
.chip-wrapper.drawerClosed {
    width:              43px;
    margin-left:        10px;
}
.chip-wrapper.drawerClosed .chip-filter-handle,
.chip-wrapper.drawerClosed .chip-pivot {
    display: none;
}
.chip-wrapper.drawerClosed span {
    margin-left: 12px;
    display:block;
}
.chip-wrapper.drawerClosed span {
    font-weight: bold;
}

.chip-wrapper.selected{
    background-color: #ffd54f;
    border: 1px solid #c8a415;
    color: black;
}
.chip-wrapper.disabled{
    background-color: #dddddd;
    color: #aaaaaa;
    cursor: default;
    border: 1px solid #aaaaaa;
}

.chip-wrapper.hidden{
    display: none;
}

.chip-wrapper .pivot-handle{
    position: absolute;
    color: #616161;
    background-color: white;
    border: 1px solid #c8a415;
    top: 3px;
    left: -10px;
    width: 25px;
    height: 27px;
    border-radius: 5px;
    transition: transform .1s;
    cursor: pointer;
    line-height: 27px;
    font-size: 15px;
    font-weight: 600;
    z-index: 1000;
}

.chip-wrapper .pivot-handle:hover{
    transform: scale(1.1);
}

.chip-wrapper .pivot-handle.selected{
    color: white;
    background-color: #519657;
    border: 1px solid white;
}

.chip-wrapper .chip-drag-handle{
    position: absolute;
    color: #616161;
    background-color: transparent;
    top: 0px;
    left: 0px;
    width: 27px;
    height: 27px;
    border-radius: 5px;
    transition: transform .1s;
    cursor: pointer;
}
.chip-wrapper .chip-filter-handle{
    position: absolute;
    color: #616161;
    background-color: white;
    border: 1px solid #c8a415;
    top: 3px;
    left: 3px;
    width: 27px;
    height: 27px;
    border-radius: 5px;
    transition: transform .1s;
    cursor: pointer;
    z-index: 100;
}
.chip-wrapper .chip-pivot {
    position: absolute;
    color: #616161;
    background-color: white;
    border: 1px solid #c8a415;
    top: 3px;
    right: 3px;
    width: 27px;
    height: 27px;
    border-radius: 5px;
    transition: transform .1s;
    cursor: pointer;
    z-index: 100;
    text-align: center;
}
.chip-wrapper .chip-pivot.selected {
    background-color: rgb(81, 150, 87);
    border: 1px solid white;
    color: white;
}
.chip-wrapper .chip-pivot span {
    display: inline-block;
    position: relative;
    top: 2px;
    font-weight: bold;
    font-size: 16px;
}
.chip-wrapper .chip-filter-handle.eye
{
    position: absolute;
    left: 168px;
}

.chip-wrapper .chip-filter-handle.active,
.chip-wrapper .chip-filter-handle.active:hover {
    color: white;
    background-color: #519657 !important;
    border: 1px solid white;
}

.chip-wrapper .chip-filter-handle:hover{
    transform: scale(1.1);
}

.chip-wrapper .chip-filter-handle:not(.active):hover{
    background-color: white !important;
}

.chip-wrapper .chip-filter-handle svg{
    height: 27px;
    width: 27px;
}

.chip-wrapper .chip-filter-handle svg.icon.edit {
    height: 25px;
    width: 25px;
}

.chip-wrapper .chip-filter-handle svg.icon.view {
    margin-top: 2px;
    margin-left: 2px;
    height: 22px;
    width: 22px;
}


.chip-wrapper .chip-label {
    margin-left:   37px;
    width:         129px;
    overflow:      hidden;
    display:       inline-block;
    white-space:   pre;
    text-overflow: ellipsis;
}

.chip-wrapper .chip-label input {
    width:         104px;
}

.chip-wrapper.pivoted {
    background-color: rgba(81, 150, 87, 0.25);
}


.dimensions-container .pivoted {
    visibility: hidden;
    height: 0;
    padding: 0;
    margin: 0;
}

.dimensions-container .filters-row,
.measures-container   .filters-row,
.layouts-container    .filters-row {
    height:     calc( 100vh - 123px );
    width:      235px;
    overflow-y: auto;
    overflow-x: hidden;
}

.layouts-container    .filters-row {
    height:     calc( 100vh - 125px -  50px );
}

.dimensions-container.showInMenu,
.measures-container.showInMenu,
.layouts-container.showInMenu {
    top:            119px;
}

.filters-row.blocking:after {
    content:    '';
    position:   absolute;
    top:        0;
    left:       0;
    width:      100%;
    height:     100%;
    background: rgba(255, 255, 255, .5);
    z-index:    99999;
}

.drawerClosed .filters-row {
    width:      67px;
}


.zoom3  .dimensions-container         .filters-row { height: calc(     77vh -  73px - ( 50px * var(--numeroDimensioniPivot) ) ); }
.zoom2  .dimensions-container         .filters-row { height: calc(   83.5vh -  73px - ( 50px * var(--numeroDimensioniPivot) ) ); }
.zoom1  .dimensions-container         .filters-row { height: calc(     91vh -  73px - ( 50px * var(--numeroDimensioniPivot) ) ); }
.zoom-1 .dimensions-container         .filters-row { height: calc(    111vh -  73px - ( 50px * var(--numeroDimensioniPivot) ) ); }
.zoom-2 .dimensions-container         .filters-row { height: calc(    125vh -  73px - ( 50px * var(--numeroDimensioniPivot) ) ); }
.zoom-3 .dimensions-container         .filters-row { height: calc(    143vh -  73px - ( 50px * var(--numeroDimensioniPivot) ) ); }

.zoom3  .layouts-container            .filters-row { height: calc(     77vh - 170px ); }
.zoom2  .layouts-container            .filters-row { height: calc(   83.5vh - 170px ); }
.zoom1  .layouts-container            .filters-row { height: calc(     91vh - 170px ); }
.zoom-1 .layouts-container            .filters-row { height: calc(    111vh - 170px ); }
.zoom-2 .layouts-container            .filters-row { height: calc(    125vh - 170px ); }
.zoom-3 .layouts-container            .filters-row { height: calc(    143vh - 170px ); }


        .dimensions-container.pivoted .filters-row { height: calc(    100vh - 139px - ( 40px * var(--numeroDimensioniPivot) ) ); }
        .measures-container           .filters-row { height: calc(    100vh - 161px ); }
.zoom3  .dimensions-container.pivoted .filters-row { height: calc(     77vh - 139px - ( 40px * var(--numeroDimensioniPivot) ) ); }
.zoom3  .measures-container           .filters-row { height: calc(     77vh - 161px ); }
.zoom2  .dimensions-container.pivoted .filters-row { height: calc(   83.5vh - 139px - ( 40px * var(--numeroDimensioniPivot) ) ); }
.zoom2  .measures-container           .filters-row { height: calc(   83.5vh - 161px ); }
.zoom1  .dimensions-container.pivoted .filters-row { height: calc(     91vh - 139px - ( 40px * var(--numeroDimensioniPivot) ) ); }
.zoom1  .measures-container           .filters-row { height: calc(     91vh - 161px ); }
.zoom-1 .dimensions-container.pivoted .filters-row { height: calc(    111vh - 139px - ( 40px * var(--numeroDimensioniPivot) ) ); }
.zoom-1 .measures-container           .filters-row { height: calc(    111vh - 161px ); }
.zoom-2 .dimensions-container.pivoted .filters-row { height: calc(    125vh - 139px - ( 40px * var(--numeroDimensioniPivot) ) ); }
.zoom-2 .measures-container           .filters-row { height: calc(    125vh - 161px ); }
.zoom-3 .dimensions-container.pivoted .filters-row { height: calc(    143vh - 139px - ( 40px * var(--numeroDimensioniPivot) ) ); }
.zoom-3 .measures-container           .filters-row { height: calc(    143vh - 161px ); }


.drawerClosed .dimensions-container   .filters-row,
.drawerClosed .measures-container     .filters-row {
    width:      70px;
}

/*
.cube-builder .filters-wrap {
    display:            inline-block;
    position:           relative;
    height:             calc( 100vh - 75px );
    width:              calc( 168px + 24px );
    padding-bottom:     5px;
    margin-top:         10px;
    margin-right:       5px;
    vertical-align:     top;
    transition:         width 0.5s;
}
.cube-builder .filters-wrap.close{
    width:              25px;
}

.cube-builder .filters-container {
    display:            inline-block;
    position:           relative;
    height:             100%;
    width:              168px;
    padding:            3px;
    border-top-right-radius:    6px;
    border-bottom-right-radius: 6px;
    background-color:   #d7e5d8;
    box-shadow:         4px 5px 9px -6px rgba(0, 0, 0, 0.75);
    transition:         width 0.5s;
    overflow-x:         hidden;
}
.cube-builder .close .filters-container{
    width:              0 !important;
}
*/
.maniglia {
    display:            inline-block;
    position:           absolute;
    color:              rgba(0, 0, 0, 1);
    text-align:         center;
    background-color:   #d7e5d8;
    width:              90px;
    height:             18px;
    cursor:             pointer;
    border-top-left-radius:  6px;
    border-top-right-radius: 6px;
    box-shadow:         0 8px 0 0 #d7e5d8, 7px -4px 9px -6px rgba(0, 0, 0, 0.75), 9px 3px 9px -6px rgba(0, 0, 0, 0.75);
    left:               194px;
    transform:          rotate(90deg) translateY(-50%);
    user-select:        none;
    /* transition:         left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms; */
}
.maniglia.menu {
    top:                107px;
}
.maniglia.dimensions {
    top:                calc( 107px + 100px ) ;
}
.maniglia.measures {
    top:                calc( 107px + 100px + 100px ) ;
}
.maniglia.layouts {
    top:                calc( 107px + 100px + 100px + 100px ) ;
}
.maniglia.grafici {
    top:                calc( 107px + 100px + 100px + 100px  + 100px ) ;
}
.maniglia.enabled {
    background-color:   #519657;
    color:              white;
}

.drawerClosed .maniglia {
    left:               27px !important;
}
/*
.cube-builder .custom-scrollbar {
    z-index:            3;
}
.cube-builder .filters-row{
    display: inline-block;
    margin-left: 0 !important;
}
.cube-builder .filters-label{
    line-height: 33px;
    width: 80px;
}*/
.add-filter svg{
    font-size: 30px;
    margin-left: 5px;
    margin-top: 21px;
    color: #519657;
    cursor:pointer;
}
.add-filter:hover svg{
    transform: scale(1.1);
}/*
.filters-select-all{
    width: 200px;
    float: right;
    text-align: right;
}
.filters-select-all a{
    color: #519657;
}

.MuiContainer-root {
    padding-left:  0 !important;
    padding-right: 0 !important;
}
*/
.showInMenu {
    position:       fixed;
    z-index:        1200;
    left:           4px;
}

.search-container.showInMenu {
    top:            68px;
    left:           -4px;
    width:          243px;
    box-shadow:
              0 2px 4px  -1px rgba(0, 0, 0, 0.20)
            , 0 4px 5px   0   rgba(0, 0, 0, 0.14)
;
}
.drawerClosed .search-container {
    width:          75px;
}
.drawerClosed .search-container .chip-wrapper {
    width:          35px;
}

.pivot-dimensions {
    bottom:         0;
    margin-left:    -3px;
    padding-top:    10px;
    padding-right:  25px;
    padding-left:   13px;
    padding-bottom: 5px;
    border-top:     1px solid #509556;
    border-radius:  0 !important;
    height:         var(--altezzaSezionePivot);
}

.pivot-dimensions .chip-wrapper {
    margin:         0;
}

.pivot-dimensions > span {
    background-image:       linear-gradient(to right,  green 1px, transparent 1px),
                            linear-gradient(to bottom, green 1px, transparent 1px),
                            linear-gradient(to left,   green 1px, transparent 1px);
    background-size:        100% 50%, 100% 50%, 100% 50%;
    background-position:    top left, top left, top right;
    background-repeat:      no-repeat;
    background-color:       white;
    border-radius:          5px;
}

.pivot-dimensions .label-container {
    display:                block;
    position:               absolute;
    top:                    -13px;
    left:                   calc( 50% - 50px );
    font-size:              12px;
    font-weight:            bold;
    background-color:       #fafafa;
    height:                 26px;
    width:                  100px;
    border-radius:          5px 5px 0 0;
}
.pivot-dimensions .label-container .upperhalf {
    width:                  100%;
    height:                 50%;
    border-top:             1px solid green;
    border-left:            1px solid green;
    border-right:           1px solid green;
    border-radius:          5px 5px 0 0;
    position:               absolute;
    top:                    0;
}
.pivot-dimensions .label-container .lowerhalf {
    width:                  100%;
    height:                 50%;
    position:               absolute;
    bottom:                 0;
}
.pivot-dimensions .label-container .inner-label {
    width:                  60%;
    height:                 20px;
    position:               absolute;
    top:                    50%;
    left:                   50%;
    transform:              translate(-50%, -50%);
    text-align:             center;
    z-index:                100;
}

.drawerClosed .pivot-dimensions > span {
    text-align:     left;
}

.drawerClosed .pivot-dimensions {
    padding:        5px 15px 5px 12px;
    border:         0;
    border-top:     1px solid #509556;
    background-color: rgb(245,245,245);
    height:         var(--altezzaSezionePivot);
}

.pivot-dimensions-container .filters-row .pivoted:not(:first-of-type) {
    margin-top: 10px;
}

.show { display: block; }
.hide { display: none !important;  }

.dialog {
    position:   absolute;
    left:       10px;
    top:        10px;
    opacity:    1;
    transition: opacity 0.5s, background-color 0.5s;
}

div.options.dialog.expanded > div > div {
    overflow-y: hidden;
}

/*
.dialog .grid-wrapper,
.dialog table,
.dialog .selected-items {
    background: rgba(255,255,255,0.1);
}
#alert-dialog-slide-title {
    background: white;
}

.opa .react-draggable {
    background-color: rgba(255,255,255,0.1);
}*/
.opa {
    opacity: 0.4;
}
#button-opacity {
    text-align: right;
}

.filter-custom    table thead tr:nth-of-type(2) th:first-of-type div,
.accordionDetails table thead tr:nth-of-type(2) th:first-of-type div{
    visibility: hidden;
}

.filter-custom table thead tr:nth-of-type(2) th:nth-of-type(2) div div {
    margin-left: 0;
}

.filters-num {
}

.newLayout {
    background-color:       #d7e5d8;
    cursor:                 pointer !important;
    text-align:             center;
}

.newLayout span {
    margin-left:            0     !important;
    width:                  120px !important;
    font-weight:            500;
    color:                  rgb(50,50,50);
}

.newLayout:hover {
    border:                 1px solid #d7e5d8;
    outline:                1px solid #aeaeae;
    box-shadow:             inset 0 0 5px 0 hsl(0, 0%, 80%);
}
.newLayout:hover span {
    color:                  black;
}

#body-layouts .chip-wrapper.drawerClosed.newLayout span {
    margin-left:        0 !important;
    width:              auto !important;
}

#body-grafici .chip-wrapper .chip-filter-handle {
    display:            none;
}
#body-grafici .chip-wrapper  {
    display:            flex;
    padding:            0;
    justify-content:    center;
    align-items:        center;
}
#body-grafici .chip-wrapper .chip-label {
    display:            flex;
    align-items:        flex-end;
    min-width:          65px ;
    margin-left:        0;
    width:              65px;
}
#body-grafici .chip-wrapper.drawerClosed .chip-label {
    margin-left:        10px;
    min-width:          unset;
    width:              auto;
}
#body-grafici .chip-wrapper .chip-label svg {
    margin-right:       7px;
}

.LayoutNameTextfield,
.defaultCheckbox {
    margin-top:   5px !important;
    margin-left: 15px !important;
    display: inline-block;
}

.LayoutNameTextfield,
.defaultCheckbox {
    margin-left: 5px !important;
}

.defaultCheckbox {
    margin-left: 15px !important;
    align-items: flex-end !important;
}
.defaultCheckbox > span {
    padding-top: 18px !important;
    padding-bottom: 0 !important;
}

.grid-wrapper thead > tr:nth-child(2) > th:nth-child(1) {
    padding-left: 16px;
}

.isDefault  {
    position: relative;
    overflow: unset;
}

.isDefault:before {
    content:        " ";
    position:       absolute !important;
    z-index:        -1;
    top:            -5px;
    left:           -5px;
    right:          -5px;
    bottom:         -5px;
    border:         2px solid green;
    border-radius:  7px;
}

#alert-dialog-slide-title {
    padding-top:    0;
    padding-bottom: 0;
}

#alert-dialog-slide-description {
    overflow-y: hidden;
    position: relative;
    /* max-height: calc(100vh - 200px); */
}

#alert-dialog-slide-description + div { /* il footer del dialog */
    padding-top:    0;
    padding-bottom: 0;
}

#alert-dialog-slide-description > div {
    overflow:       auto;
}

#alert-dialog-slide-description.R > div {
    overflow:       unset;
}

.options.dialog div[role="dialog"] {
    width:          650px;
    max-width:      unset;
    transition:     width 0.5s;
}

.options.dialog.expanded div[role="dialog"] {
    width:          70vw;
}

.options.dialog .SimpleTable .check-sel-all {
    left:           12px; /* eccezione */
}

.options.dialog .SimpleTable thead tr:nth-of-type(2) th {
    top:            37px; /* eccezione */
}

#alert-dialog-slide-description table {
    width:          100%;
    max-width:      unset;
}

#alert-dialog-slide-description + div button {
    margin-left:    15px;
}

#alert-dialog-slide-description .SimpleTable-wrapper.for-users {
    width:          49% !important;
}
#alert-dialog-slide-description .SimpleTable-wrapper.for-users:first-of-type {
    margin-right:   10px;
}

.accordionLabel span,
span.accordionLabel {
    color:          grey;
    font-size:      1rem;
    font-family:    "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight:    400;
    line-height:    1.5;
    letter-spacing: 0.00938em;
}

label.accordionLabel {
    margin-top:     5px;
    margin-left:    15px;
}

.secondaryHeading {
    height:         100%;
    margin-left:    auto;
}

.accordionSummary {
    min-height:     48px !important;
}
.accordionSummary > .Mui-expanded {
    margin-top:    0 !important;
    margin-bottom: 0 !important;
}
.accordionSummary div {
    display:         flex;
    flex-direction:  row;
    justify-content: flex-start;
    align-items:     center;
    flex-wrap:       nowrap;
}

.boxContainer {
    display:         inline-block;
    width:           100%;
    margin-top:      20px;
}

.boxContainer > div,
.boxContainer > label {
    margin-right:   15px;
}

.boxContainer label {
    line-height: 25px;
    font-size: 0.875rem;
}

.chip-filter-handle .fa-filter {
    position: relative;
    top: 6px;
    left: 4px;
}
/*
#alert-dialog-slide-description div:nth-of-type(2) .accordionSummary {
    width:   200px;
    overflow: hidden;
    justify-content: flex-start;
}


#alert-dialog-slide-description div:nth-of-type(2) .accordionSummary > div {
    width:   200px;
}*/

.accordionDetails table td {
    padding-top:    0;
    padding-bottom: 0;
}

.accordionDetails {
    position: relative;
}

.accordionDetails .checkOnlySelected {
    position: absolute;
    top: 0;
    left: 14px;
}

.FILTERS .check > span {
    height: 20px;
    width: 20px;
}

.accordionDetails.checkbox-invisible .check,
.accordionDetails.checkbox-invisible .check > span {
    user-select:    none;
}

.accordionDetails.checkbox-invisible .check > span > span,
.accordionDetails.checkbox-invisible .check > span > span > input,
.accordionDetails.checkbox-invisible .check > span > span > svg {
    visibility:     hidden;
}
.accordionDetails.checkbox-invisible .check > span > span > input[checked],
.accordionDetails.checkbox-invisible .check > span > span > input[checked] + svg {
    visibility:     visible !important;
}

.accordionDetails .SimpleTable-wrapper {
    margin-top: 30px;
}
.accordionDetails .SimpleTable-wrapper > div {
    height: 100%;
}


.accordionDetails .grids > div {
    display: inline-block;
}

.accordionDetails .no-data td {
    padding-top: 10px;
    border-bottom: 0;
}

span.sharedwith {
    color: grey;
}

#alert-dialog-slide-title h2 {
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    cursor:          move;
}
#alert-dialog-slide-title h2 button {
    padding-left:   2px;
    padding-right:  2px;
}

/*
.shared {
    background-size:     33px 33px;
    background-position: -50% -50%;
}

.shared.ToMe {
    background-image:    url(../.././icon_shared_to_me_white.png);
}
.shared.ByMe {
    background-image:    url(../.././icon_shared_by_me_all_green_light.png);
}

.shared .chip-label {
    color:       black;
    font-weight: 500;
    text-shadow:
            -1px -1px 5px white,
             1px -1px 5px white,
            -1px  1px 5px white,
             1px  1px 5px white !important;
}
*/
.checkShowAll {
    float:      right;
}

td.filters > span {
    display: flex;
    align-items: center;
}

td.filters > span > span {
    width: 230px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
}

td.filters > span > svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.ListAltIcon {
    color: rgb(74,74,74);
    margin-right: 5px;
}

div.shared {
    position: relative;
}

div.shared:before {
    position: relative;
}

div.shared hr {
    margin-top:     10px;
    margin-bottom:  10px;
}

div.shared hr:before {
    position:   absolute;
    margin-top: -9px;
    left:       55px;
    height:     12px;
    width:      116px;
    font-size:  10px;
    background-color: rgb(245,245,245);
    padding:    3px;
    text-align: center;
}

.drawerClosed div.shared hr:before {
    display:    none;
}

div.shared.byme hr:before {
    content:    'SHARED BY ME';
}

div.shared.tome hr:before {
    content:    'SHARED WITH ME';
}

.filters-row .MuiCheckbox-colorSecondary.Mui-checked,
.chip-wrapper.selected svg {
    color: #509556;
}

#body-search .chip-wrapper div,
#body-search .chip-wrapper input {
    padding:            0;
    margin:             0;
    height:             100%;
}
#body-search .chip-wrapper  {
    padding:            0;
    background-color:   #e6efe7;
    margin-top:         5px;
    margin-bottom:      5px;
    margin-left:        18px;
}

#body-search > div > div > div > div > svg {
    margin:             6px;
}

.dimensions-container  .not-searched
,.measures-container    .not-searched
,.layouts-container     .not-searched {
    opacity: 0.5;
}

.dimensions-container  :not(.selected).not-searched
,.measures-container    :not(.selected).not-searched
,.layouts-container     :not(.selected).not-searched {
    display: none;
}

#alert-dialog-slide-description table > tbody > tr > td.reset > button {
    padding:    0;
    min-width:  0;
}

#body-search .search-cancel-X {
    padding:    3px;
}

.options.dialog .sharedwith {
    margin:     10px !important;
}

.dialog .inner-loading {
    display:            inline-block;
    position:           absolute;
    z-index:            111;
    height:             98%;
    width:              92%;
    background-color:   rgba(255, 255, 255, .75);
}


.dialog .dialog-title-container {
    display:            flex;
    align-items:        center;
}

.dialog .dialog-title-container .prefisso {
    font-weight:        500;
}

.dialog .dialog-title-container .nome {
    font-weight:        600;
}

.dialog .dialog-title-container .suffisso {
    font-size:          15px;
    font-weight:        400;
    color:              grey;
}


[name="filterLikeInizio"],
[name="filterLikeCentro"],
[name="filterLikeFine"] {
    min-width: 170px;
    width: 170px;
    max-width: 170px;
}

.coloredSwitch {
    margin-top: 10px;
    margin-bottom: 10px;
}