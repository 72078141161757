html{
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: #f5f5f6 !important;

}
#root{
  height: 100%;
}
.App{
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.dialog-separator{
  height:1px;
  border-bottom: 1px solid #cccccc;
}

.MuiMenu-paper .MuiListItem-button:hover {
  background-color: rgba(215,229,216,1) !important;
}

.bold {
  font-weight: bold;
}
